.nobreak {
  white-space: nowrap;
}
.nohyphens {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
}
.hideme {
  display: none !important;
}
::-moz-selection {
  background: #eb6c5f;
  color: #ffffff;
}
::selection {
  background: #eb6c5f;
  color: #ffffff;
}
* {
  -webkit-tap-highlight-color: rgba(235, 108, 95, 0.1);
  box-sizing: border-box;
}
main {
  display: block;
}
html {
  -webkit-text-size-adjust: 100%;
          text-size-adjust: 100%;
}
body {
  letter-spacing: calc((1rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  background: #ffffff;
  color: #111111;
  font-family: "Roboto", sans-serif;
  -webkit-font-feature-settings: "liga", "kern";
          font-feature-settings: "liga", "kern";
  -webkit-font-kerning: normal;
          font-kerning: normal;
  font-smoothing: antialiased;
  font-weight: 300;
  hyphenate-limit-chars: 6 3 2;
  hyphenate-limit-lines: 2;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 160rem;
  position: relative;
  text-rendering: geometricPrecision;
}
@media (min-width: 20rem) {
  body {
    font-size: calc(1rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  body {
    font-size: 1.25rem;
  }
}
body.no_scroll {
  overflow: hidden;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-hyphens: none;
      -ms-hyphens: none;
          hyphens: none;
  word-wrap: break-word;
}
h1 {
  letter-spacing: calc((1.5625rem + 1.3125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.5625rem;
  color: #eb6c5f;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  line-height: 1.1;
  margin: 0 0 0.75em;
  text-transform: uppercase;
}
@media (min-width: 20rem) {
  h1 {
    font-size: calc(1.5625rem + 1.3125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h1 {
    font-size: 2.875rem;
  }
}
h2 {
  letter-spacing: calc((1.3125rem + 0.4375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.3125rem;
  color: #eb6c5f;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.5em;
}
@media (min-width: 20rem) {
  h2 {
    font-size: calc(1.3125rem + 0.4375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h2 {
    font-size: 1.75rem;
  }
}
h3 {
  letter-spacing: calc((1.125rem + 0.375
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.125rem;
  color: #111111;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding-top: 0.75em;
}
@media (min-width: 20rem) {
  h3 {
    font-size: calc(1.125rem + 0.375
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h3 {
    font-size: 1.5rem;
  }
}
h4 {
  letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #111111;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0 0 0.75em;
}
@media (min-width: 20rem) {
  h4 {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h4 {
    font-size: 1.125rem;
  }
}
h5 {
  letter-spacing: calc((1rem + 0.125
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1rem;
  color: #111111;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1.35;
  margin: 0;
}
@media (min-width: 20rem) {
  h5 {
    font-size: calc(1rem + 0.125
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  h5 {
    font-size: 1.125rem;
  }
}
h6 {
  height: 0;
  margin: 0;
  overflow: hidden;
  width: 0;
}
p {
  margin: 0 0 0.75em;
}
a {
  color: #111111;
  -webkit-transition: color 600ms;
  transition: color 600ms;
}
a:active,
a.active {
  color: #111111;
  outline: 0;
}
a:focus {
  outline: 0.1875rem solid rgba(235, 108, 95, 0.8);
  outline-offset: 0.0625rem;
  text-decoration: none;
}
a:focus[data-focus-method="mouse"],
a:focus[data-focus-method="touch"] {
  outline: 0;
}
a:hover {
  text-decoration: none;
}
a[tabindex="-1"]:focus {
  outline: 0;
}
a[tabindex="-1"][id] {
  position: relative;
}
a[tabindex="-1"][id]:after {
  content: "";
}
a.touch_link {
  color: #111111;
  display: block;
  -webkit-box-flex: 1;
          flex-grow: 1;
  overflow: hidden;
  text-decoration: none;
}
a.touch_link .title {
  color: #111111;
  display: inline-block;
  margin-bottom: 0.375em;
  outline-offset: 0;
  padding: 0;
  text-decoration: none;
  -webkit-transition: color 600ms;
  transition: color 600ms;
}
a.touch_link .title.active {
  color: #111111;
}
.highlighted {
  -webkit-animation: highlighted_anchor 1800ms;
          animation: highlighted_anchor 1800ms;
}
strong {
  font-weight: 500;
}
em {
  font-style: normal;
}
blockquote {
  margin: 0 0 0.75em 1ch;
  text-indent: -1ch;
}
blockquote:before {
  content: "\201E";
}
blockquote:after {
  content: "\201C";
}
small {
  font-size: 85%;
}
sup {
  font-size: 85%;
  line-height: 1;
  position: relative;
  top: -0.3125rem;
}
sub {
  bottom: -0.125rem;
  font-size: 85%;
  line-height: 1;
  position: relative;
}
.table {
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1.5em;
  overflow-y: auto;
}
* + .table {
  margin-top: 1.5em;
}
table {
  border-bottom: 0.125rem solid #eaeaea;
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0;
  width: 100%;
}
th {
  font-weight: 700;
  text-align: left;
}
td,
th {
  border-top: 0.125rem solid #eaeaea;
  padding: 0.5em;
  -webkit-transition: background 600ms;
  transition: background 600ms;
  vertical-align: top;
}
tr:hover td,
tr:hover th {
  background: #f7f7f7;
}
ol {
  counter-reset: list;
  list-style: none;
  margin: 0 0 0.75em;
  padding: 0;
}
ol > li {
  padding-left: 1.5em;
  position: relative;
}
ol > li:before {
  color: #eb6c5f;
  content: counter(list) ".";
  counter-increment: list;
  font-weight: 700;
  left: 0;
  line-height: 1.5;
  position: absolute;
  text-align: right;
  width: 1.125em;
}
ol ol,
ol ul {
  margin: 0;
}
ul {
  list-style: none;
  margin: 0 0 0.75em;
  padding-left: 1.125em;
}
ul > li {
  margin: 0.375em 0 0.375em 0.375em;
  padding-left: 0.375em;
  position: relative;
}
ul > li::after {
  background: #eb6c5f;
  bottom: 0.0625rem;
  content: "";
  left: -1.125em;
  position: absolute;
  top: 0.0625rem;
  width: 0.25rem;
  z-index: 1;
}
ul ol,
ul ul {
  margin: 0;
}
.defined_list dl {
  border-bottom: 0.25rem solid #eaeaea;
  margin: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dl {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
}
.defined_list dt {
  border-top: 0.25rem solid #eaeaea;
  font-weight: 700;
  padding: 0.75em 0;
}
@media (min-width: 35rem) {
  .defined_list dt {
    -webkit-box-flex: 0;
            flex: 0 0 40%;
    max-width: 40%;
    padding: 0.75em 0.75em 0.75em 0;
  }
}
.defined_list dd {
  margin: 0;
}
@media (min-width: 35rem) {
  .defined_list dd {
    border-top: 0.25rem solid #eaeaea;
    -webkit-box-flex: 0;
            flex: 0 0 60%;
    max-width: 60%;
    padding: 0.75em 0.5em 0 0;
  }
}
nav {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
nav ol {
  margin: 0;
}
nav ul {
  list-style: none!important;
  margin: 0;
  padding: 0;
}
nav ul li:after {
  content: none;
}
nav li {
  margin: 0;
  padding: 0;
}
nav li:before {
  display: none;
}
figure {
  margin: 0 0 1.5em;
}
figure img {
  display: block;
}
figure.center {
  margin-left: auto;
  margin-right: auto;
}
figure.floatleft {
  float: left;
  margin-right: 1.5em;
}
figure.floatright {
  float: right;
  margin-left: 1.5em;
}
figcaption {
  font-size: 85%;
  text-align: left;
}
.img_container {
  overflow: hidden;
}
.img_container img,
.img_container svg {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}
.img_resize {
  height: 0;
  position: relative;
  width: 100%;
}
img {
  border: 0;
  display: inline-block;
  height: auto;
  max-width: 100%;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
svg {
  height: 100%;
  -webkit-transform: scale(1);
          transform: scale(1);
  width: 100%;
}
svg a:focus {
  outline: 0;
}
input,
select,
textarea,
button {
  font-family: inherit;
  font-weight: 400;
  letter-spacing: calc((1rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1rem;
}
@media (min-width: 20rem) {
  input,
  select,
  textarea,
  button {
    font-size: calc(1rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  input,
  select,
  textarea,
  button {
    font-size: 1.25rem;
  }
}
label {
  cursor: pointer;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type="time"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-inner-spin-button,
input[type="time"]::-webkit-outer-spin-button,
input[type="date"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #111111;
  box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2) !important;
}
input::-ms-clear {
  display: none;
  height: 0;
  width: 0;
}
::-webkit-input-placeholder {
  color: #515151;
  -webkit-user-select: none;
          user-select: none;
}
:-ms-input-placeholder {
  color: #515151;
  -ms-user-select: none;
      user-select: none;
}
::placeholder {
  color: #515151;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.required_asterisk {
  color: #eb6c5f;
}
.required_description {
  font-size: 85%;
}
.error_warning {
  background: #eb6c5f;
  color: #ffffff;
  display: none;
  font-weight: 700;
  margin-right: 0.375em;
  padding: 0 0.375em;
  text-align: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.error_warning.show {
  display: inline-block;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
.legend {
  letter-spacing: calc((1.3125rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
  font-size: 1.3125rem;
  color: #111111;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 1.35;
  margin: 0 0 0.75em;
  padding: 0.75em 0 0;
  width: 100%;
}
@media (min-width: 20rem) {
  .legend {
    font-size: calc(1.3125rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .legend {
    font-size: 1.5625rem;
  }
}
.legend .label {
  margin-bottom: 0;
}
.label {
  display: inline-block;
  margin-bottom: 0.375em;
}
.input,
.textarea {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: #ffffff;
  border: 0;
  border-bottom: 0.25rem solid #eaeaea;
  border-radius: 0;
  box-shadow: 0 0 0 #eaeaea inset;
  color: #111111;
  margin: 0 0 1em;
  outline: 0;
  width: 100%;
}
.input[aria-invalid="true"],
.textarea[aria-invalid="true"] {
  border-color: #eb6c5f;
}
.input[aria-invalid="true"]:focus,
.textarea[aria-invalid="true"]:focus {
  border-color: #eb6c5f;
  box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  color: #111111;
}
.input:focus,
.textarea:focus {
  border-color: #eb6c5f;
  box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  color: #111111;
}
.input {
  padding: 0 0.75em;
  -webkit-transition: border 600ms;
  transition: border 600ms;
  height: 2.75rem;
}
@media (min-width: 20rem) {
  .input {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  .input {
    height: 3.375rem;
  }
}
.textarea {
  line-height: 1.5;
  overflow: hidden;
  padding: 0.75em;
  resize: none;
  -webkit-transition: border 600ms, height 600ms;
  transition: border 600ms, height 600ms;
}
.control {
  -webkit-box-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: flex;
  margin: 0.375em 0.75em 0.75em 0;
}
.control.checkbox input:checked ~ .indicator .icon {
  opacity: 1;
  visibility: visible;
}
.control input {
  border: 0;
  cursor: pointer;
  height: 1.5rem;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 1.5rem;
  z-index: 10;
}
.control input[aria-invalid="true"] ~ .indicator {
  border-color: #eb6c5f;
}
.control input[aria-invalid="true"]:focus ~ .indicator {
  border-color: #eb6c5f;
  box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  color: #111111;
}
.control input:focus ~ .indicator {
  border-color: #eb6c5f;
  box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  color: #111111;
}
.control .indicator_wrapper {
  -webkit-box-align: center;
          align-items: center;
  display: -webkit-box;
  display: flex;
  flex-shrink: 0;
  margin-right: 0.75em;
  position: relative;
}
.control .indicator {
  background: #ffffff;
  border: 0.25rem solid #eaeaea;
  border-radius: 0;
  height: 1.375rem;
  position: relative;
  -webkit-transition: border 600ms;
  transition: border 600ms;
  width: 1.375rem;
}
.control .indicator .icon {
  fill: #111111;
  height: 0.9375rem;
  opacity: 0;
  position: absolute;
  -webkit-transition: opacity 600ms;
  transition: opacity 600ms;
  visibility: hidden;
  width: 0.9375rem;
}
.select_wrapper {
  display: block;
}
.select {
  background: #ffffff;
  border: 0.25rem solid #eaeaea;
  border-radius: 0;
  color: #111111;
  cursor: pointer;
  display: block;
  margin: 0 0 1em;
  padding: 0 0.75em;
  position: relative;
  -webkit-transition: border 600ms;
  transition: border 600ms;
  width: 100%;
}
.select:before {
  border: 0.625rem solid transparent;
  border-top-color: #111111;
  content: "";
  display: inline-block;
  height: 0;
  margin-top: -0.3125rem;
  pointer-events: none;
  position: absolute;
  right: 0.75em;
  top: 50%;
  width: 0;
}
.select.error {
  border-color: #eb6c5f;
}
.select.error.focus {
  border-color: #eb6c5f;
  box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  color: #111111;
}
.select.focus {
  border-color: #eb6c5f;
  box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  color: #111111;
}
select {
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: 0;
  color: #111111;
  cursor: pointer;
  margin: 0;
  outline: 0;
  overflow: hidden;
  padding: 0.75rem 0.625rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 2.25rem;
}
@media (min-width: 20rem) {
  select {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select {
    min-height: 2.875rem;
  }
}
select option {
  min-height: 2.25rem;
}
@media (min-width: 20rem) {
  select option {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media (min-width: 93.75rem) {
  select option {
    min-height: 2.875rem;
  }
}
select::-ms-expand {
  display: none;
}
select:focus:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #111111;
}
select:focus::-ms-value {
  background: none;
  color: #111111;
}
@-webkit-keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes preloader_rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(235, 108, 95, 0.1);
    box-shadow: 0 0 0 2.5rem rgba(235, 108, 95, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@keyframes highlighted_anchor {
  0% {
    background: transparent;
    box-shadow: none;
  }
  70% {
    background: rgba(235, 108, 95, 0.1);
    box-shadow: 0 0 0 2.5rem rgba(235, 108, 95, 0.1);
  }
  100% {
    background: transparent;
    box-shadow: none;
  }
}
@-webkit-keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes image_gallery_spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@-webkit-keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_right {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: -1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@-webkit-keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@keyframes image_gallery_bounce_from_left {
  0% {
    margin-left: 0;
  }
  50% {
    margin-left: 1.875rem;
  }
  100% {
    margin-left: 0;
  }
}
@media screen {
  .visible_for_screen_reader {
    border: 0;
    clip: rect(0.0625rem, 0.0625rem, 0.0625rem, 0.0625rem);
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  abbr {
    text-decoration: none;
  }
  [role="tablist"] {
    margin-bottom: 1.5em;
  }
  * + [role="tablist"] {
    margin-top: 1.5em;
  }
  [role="tab"] {
    -webkit-box-align: center;
            align-items: center;
    background: #f7f7f7;
    border-left: 0.25rem solid #eaeaea;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 100%;
    margin: 0 0 0.375em;
    min-width: 100%;
    padding: 0.5em 0;
  }
  [role="tab"] .title {
    color: #111111;
    flex-basis: 100%;
    margin-left: 0.75em;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  [role="tab"] .icon {
    display: block;
    fill: #111111;
    height: 1.5rem;
    margin-right: 1.5em;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    width: 1.5rem;
  }
  [role="tab"][aria-expanded="true"] {
    border-color: #eb6c5f;
  }
  [role="tab"]:focus,
  [role="tab"]:hover {
    border-color: #eb6c5f;
    outline: 0;
  }
  [role="tab"]:focus .title,
  [role="tab"]:hover .title {
    color: #eb6c5f;
  }
  [role="tabpanel"] {
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  [role="tabpanel"][aria-hidden="false"] {
    margin: 1.5em 0;
  }
  .csstransitions [role="tabpanel"].animated {
    -webkit-transition: margin 1200ms, max-height 1200ms;
    transition: margin 1200ms, max-height 1200ms;
  }
  .contact_wrapper {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .contact_wrapper p {
    margin-right: 2.25em;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  .address_vcard_button {
    margin: 0.75em 0;
  }
  .address_call_button {
    margin: 0.75em 0;
  }
  .atikon_logo {
    height: 100%;
  }
  .author_hor {
    bottom: 1.5em;
    display: inline-block;
    fill: #b7b7b7;
    height: 0.5625rem;
    line-height: 0.5625rem;
    position: absolute;
    right: 1.5em;
    width: 4.6875rem;
    z-index: 6999;
  }
  .breadcrumbs {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.75em;
  }
  .breadcrumbs p {
    margin: 0 0.375em 0 0;
  }
  .breadcrumbs ol {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .breadcrumbs .separator {
    margin: 0 0.375em;
  }
  .button {
    border-radius: 0;
    padding: 0 1.5em;
    background: #eb6c5f;
    border: 0.25rem solid #eb6c5f;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .button .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button .icon + .text {
    padding-left: 0.5em;
  }
  .button:focus {
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
    outline: 0;
    background: #f1968c;
    border-color: #f1968c;
    color: #ffffff;
  }
  .button:focus .icon {
    fill: #ffffff;
  }
  .button:hover {
    background: #f1968c;
    border-color: #f1968c;
    color: #111111;
  }
  .button:hover .icon {
    fill: #111111;
  }
  .button:active,
  .button.active {
    box-shadow: 0 0 0.75rem #eb6c5f inset;
    background: #eb6c5f;
    border: 0.25rem solid #eb6c5f;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .button:active > span,
  .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .button:active .icon,
  .button.active .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .button:active .text,
  .button.active .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .button:active .icon + .text,
  .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .bypass_blocks {
    left: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    top: 0;
    z-index: 6000;
  }
  .bypass_blocks li {
    display: block;
    width: 20rem;
  }
  .bypass_blocks a {
    background: #ffffff;
    color: #eb6c5f;
    display: inline-block;
    font-size: 85%;
    height: 0.0625rem;
    left: -624.9375rem;
    overflow: hidden;
    padding: 0.375em;
    position: absolute;
    text-decoration: underline;
    top: -624.9375rem;
    width: 0.0625rem;
  }
  .bypass_blocks a:focus {
    color: #eb6c5f;
    height: auto;
    left: 0.375em;
    top: 0.375em;
    width: auto;
  }
  .steuernews_call_to_action {
    background: #f7f7f7;
    border-radius: 0;
    display: inline-block;
    font-size: 90%;
    margin: 1.5em 0 1.5em;
    padding: 0.625rem 0.9375rem 0.625rem 0.9375rem;
  }
  .steuernews_call_to_action p {
    margin: 0;
  }
  .chart_organigram {
    display: none;
  }
  .google-visualization-orgchart-table {
    border-bottom: 0;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google-visualization-orgchart-table .node {
    background: #f7f7f7;
    border: 0;
    box-shadow: none;
    cursor: pointer;
    font-size: 85%;
    padding: 0.4375rem;
    vertical-align: middle;
  }
  .google-visualization-orgchart-table .google-visualization-orgchart-linenode {
    border-color: #eaeaea;
    border-top: 0;
  }
  .edit_in_cms {
    display: none;
  }
  .scheme_red ::-moz-selection {
    background: #eb6c5f;
  }
  .scheme_red ::selection {
    background: #eb6c5f;
  }
  .scheme_red h1 {
    color: #eb6c5f;
  }
  .scheme_red h2 {
    color: #eb6c5f;
  }
  .scheme_red ol > li:before {
    color: #eb6c5f;
  }
  .scheme_red ul > li::after {
    background: #eb6c5f;
  }
  .scheme_red .button {
    background: #eb6c5f;
    border: 0.25rem solid #eb6c5f;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_red .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_red .button .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_red .button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_red .button .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_red .button:focus {
    background: #f1968c;
    border-color: #f1968c;
    color: #ffffff;
  }
  .scheme_red .button:focus .icon {
    fill: #ffffff;
  }
  .scheme_red .button:hover {
    background: #f1968c;
    border-color: #f1968c;
    color: #111111;
  }
  .scheme_red .button:hover .icon {
    fill: #111111;
  }
  .scheme_red .button:active,
  .scheme_red .button.active {
    background: #eb6c5f;
    border: 0.25rem solid #eb6c5f;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_red .button:active > span,
  .scheme_red .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_red .button:active .icon,
  .scheme_red .button.active .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_red .button:active .text,
  .scheme_red .button.active .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_red .button:active .icon + .text,
  .scheme_red .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_red .bypass_blocks a {
    color: #eb6c5f;
  }
  .scheme_red .bypass_blocks a:focus {
    color: #eb6c5f;
  }
  .scheme_red [role="tab"][aria-expanded="true"] {
    border-color: #eb6c5f;
  }
  .scheme_red [role="tab"]:focus,
  .scheme_red [role="tab"]:hover {
    border-color: #eb6c5f;
  }
  .scheme_red [role="tab"]:focus .title,
  .scheme_red [role="tab"]:hover .title {
    color: #eb6c5f;
  }
  .scheme_red .download_overview .entry:hover {
    border-color: #eb6c5f;
  }
  .scheme_red .required_asterisk {
    color: #eb6c5f;
  }
  .scheme_red .error_warning {
    background: #eb6c5f;
  }
  .scheme_red .input[aria-invalid="true"],
  .scheme_red .textarea[aria-invalid="true"] {
    border-color: #eb6c5f;
  }
  .scheme_red .input[aria-invalid="true"]:focus,
  .scheme_red .textarea[aria-invalid="true"]:focus {
    border-color: #eb6c5f;
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  }
  .scheme_red .input:focus,
  .scheme_red .textarea:focus {
    border-color: #eb6c5f;
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  }
  .scheme_red .control input[aria-invalid="true"] ~ .indicator {
    border-color: #eb6c5f;
  }
  .scheme_red .control input[aria-invalid="true"]:focus ~ .indicator {
    border-color: #eb6c5f;
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  }
  .scheme_red .control input:focus ~ .indicator {
    border-color: #eb6c5f;
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  }
  .scheme_red .select.error {
    border-color: #eb6c5f;
  }
  .scheme_red .select.error.focus {
    border-color: #eb6c5f;
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  }
  .scheme_red .select.focus {
    border-color: #eb6c5f;
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  }
  .scheme_red .travel_mode input:checked ~ .travel_mode_radio {
    background: #eb6c5f;
    border-color: #eb6c5f;
    color: #ffffff;
  }
  .scheme_red .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_red .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
    background: #eb6c5f;
    border-color: #eb6c5f;
    color: #ffffff;
  }
  .scheme_red .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_red .warnings {
    color: #eb6c5f;
  }
  .scheme_red .header_wrapper {
    background: #eb6c5f;
  }
  .scheme_red .vpi_diagram_grid_wrapper .vpi_diagram_legend circle,
  .scheme_red .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    fill: #eb6c5f;
  }
  .scheme_red .vpi_diagram_grid_wrapper .vpi_diagram_legend line,
  .scheme_red .vpi_diagram_grid_wrapper .vpi_diagram_points line {
    stroke: #eb6c5f;
  }
  .scheme_red .infolist_top_link .icon {
    fill: #eb6c5f;
  }
  .scheme_red .infolist_overview .entry:hover,
  .scheme_red .overview .entry:hover,
  .scheme_red .useful_link_overview .entry:hover {
    border-left: 0.25rem solid #eb6c5f;
  }
  .scheme_red .infolist_overview .entry:hover .title,
  .scheme_red .overview .entry:hover .title,
  .scheme_red .useful_link_overview .entry:hover .title {
    color: #eb6c5f;
  }
  .scheme_red .infolist_link_to_news:hover {
    border-left: 0.25rem solid #eb6c5f;
  }
  .scheme_red .infolist_link_to_news:hover .title {
    color: #eb6c5f;
  }
  .scheme_red .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line,
  .scheme_red .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line {
    background: #eb6c5f;
  }
  .scheme_red .offcanvas_menu .offcanvas_menu_content > ul > li > a:active {
    color: #eb6c5f;
  }
  .scheme_red .offcanvas_menu .offcanvas_menu_content > ul li.expanded > a {
    color: #eb6c5f;
  }
  .scheme_red .offcanvas_menu .offcanvas_menu_content > ul ul a {
    border-right: 0.25rem solid #eb6c5f;
  }
  .scheme_red .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #eb6c5f;
  }
  .scheme_red .popup_close {
    background: #eb6c5f;
  }
  .scheme_red .onlinetools_popup_overview .touch_link:hover .title {
    color: #eb6c5f;
  }
  .scheme_red .highlight_keyword {
    background: #eb6c5f;
  }
  .scheme_red .steuernews_video_overview .video_play .icon {
    fill: #eb6c5f;
  }
  .scheme_red .steuernews_current_issue ul > li::after {
    background: #eb6c5f;
  }
  .scheme_red .video_overview .video_play .icon,
  .scheme_red .video_infolists_overview .video_play .icon,
  .scheme_red .video_infolist_overview .video_play .icon,
  .scheme_red .video_tutorial_overview .video_play .icon {
    fill: #eb6c5f;
  }
  .scheme_violet ::-moz-selection {
    background: #bb5191;
  }
  .scheme_violet ::selection {
    background: #bb5191;
  }
  .scheme_violet h1 {
    color: #bb5191;
  }
  .scheme_violet h2 {
    color: #bb5191;
  }
  .scheme_violet ol > li:before {
    color: #bb5191;
  }
  .scheme_violet ul > li::after {
    background: #bb5191;
  }
  .scheme_violet .button {
    background: #bb5191;
    border: 0.25rem solid #bb5191;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_violet .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_violet .button .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_violet .button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_violet .button .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_violet .button:focus {
    background: #c976a8;
    border-color: #c976a8;
    color: #ffffff;
  }
  .scheme_violet .button:focus .icon {
    fill: #ffffff;
  }
  .scheme_violet .button:hover {
    background: #c976a8;
    border-color: #c976a8;
    color: #111111;
  }
  .scheme_violet .button:hover .icon {
    fill: #111111;
  }
  .scheme_violet .button:active,
  .scheme_violet .button.active {
    background: #bb5191;
    border: 0.25rem solid #bb5191;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_violet .button:active > span,
  .scheme_violet .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_violet .button:active .icon,
  .scheme_violet .button.active .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_violet .button:active .text,
  .scheme_violet .button.active .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_violet .button:active .icon + .text,
  .scheme_violet .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_violet .bypass_blocks a {
    color: #bb5191;
  }
  .scheme_violet .bypass_blocks a:focus {
    color: #bb5191;
  }
  .scheme_violet [role="tab"][aria-expanded="true"] {
    border-color: #bb5191;
  }
  .scheme_violet [role="tab"]:focus,
  .scheme_violet [role="tab"]:hover {
    border-color: #bb5191;
  }
  .scheme_violet [role="tab"]:focus .title,
  .scheme_violet [role="tab"]:hover .title {
    color: #bb5191;
  }
  .scheme_violet .download_overview .entry:hover {
    border-color: #bb5191;
  }
  .scheme_violet .required_asterisk {
    color: #bb5191;
  }
  .scheme_violet .error_warning {
    background: #bb5191;
  }
  .scheme_violet .input[aria-invalid="true"],
  .scheme_violet .textarea[aria-invalid="true"] {
    border-color: #bb5191;
  }
  .scheme_violet .input[aria-invalid="true"]:focus,
  .scheme_violet .textarea[aria-invalid="true"]:focus {
    border-color: #bb5191;
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
  }
  .scheme_violet .input:focus,
  .scheme_violet .textarea:focus {
    border-color: #bb5191;
    box-shadow: 0 0 0 0.75rem rgba(187, 81, 145, 0.2);
  }
  .scheme_violet .control input[aria-invalid="true"] ~ .indicator {
    border-color: #bb5191;
  }
  .scheme_violet .control input[aria-invalid="true"]:focus ~ .indicator {
    border-color: #bb5191;
    box-shadow: 0 0 0 0.75rem rgba(187, 81, 145, 0.2);
  }
  .scheme_violet .control input:focus ~ .indicator {
    border-color: #bb5191;
    box-shadow: 0 0 0 0.75rem rgba(187, 81, 145, 0.2);
  }
  .scheme_violet .select.error {
    border-color: #bb5191;
  }
  .scheme_violet .select.error.focus {
    border-color: #bb5191;
    box-shadow: 0 0 0 0.75rem rgba(187, 81, 145, 0.2);
  }
  .scheme_violet .select.focus {
    border-color: #bb5191;
    box-shadow: 0 0 0 0.75rem rgba(187, 81, 145, 0.2);
  }
  .scheme_violet .travel_mode input:checked ~ .travel_mode_radio {
    background: #bb5191;
    border-color: #bb5191;
    color: #ffffff;
  }
  .scheme_violet .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_violet .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.75rem rgba(187, 81, 145, 0.2);
    background: #bb5191;
    border-color: #bb5191;
    color: #ffffff;
  }
  .scheme_violet .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_violet .warnings {
    color: #bb5191;
  }
  .scheme_violet .header_wrapper {
    background: #bb5191;
  }
  .scheme_violet .vpi_diagram_grid_wrapper .vpi_diagram_legend circle,
  .scheme_violet .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    fill: #bb5191;
  }
  .scheme_violet .vpi_diagram_grid_wrapper .vpi_diagram_legend line,
  .scheme_violet .vpi_diagram_grid_wrapper .vpi_diagram_points line {
    stroke: #bb5191;
  }
  .scheme_violet .infolist_top_link .icon {
    fill: #bb5191;
  }
  .scheme_violet .infolist_overview .entry:hover,
  .scheme_violet .overview .entry:hover,
  .scheme_violet .useful_link_overview .entry:hover {
    border-left: 0.25rem solid #bb5191;
  }
  .scheme_violet .infolist_overview .entry:hover .title,
  .scheme_violet .overview .entry:hover .title,
  .scheme_violet .useful_link_overview .entry:hover .title {
    color: #bb5191;
  }
  .scheme_violet .infolist_link_to_news:hover {
    border-left: 0.25rem solid #bb5191;
  }
  .scheme_violet .infolist_link_to_news:hover .title {
    color: #bb5191;
  }
  .scheme_violet .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line,
  .scheme_violet .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line {
    background: #bb5191;
  }
  .scheme_violet .offcanvas_menu .offcanvas_menu_content > ul > li > a:active {
    color: #bb5191;
  }
  .scheme_violet .offcanvas_menu .offcanvas_menu_content > ul li.expanded > a {
    color: #bb5191;
  }
  .scheme_violet .offcanvas_menu .offcanvas_menu_content > ul ul a {
    border-right: 0.25rem solid #bb5191;
  }
  .scheme_violet .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #bb5191;
  }
  .scheme_violet .popup_close {
    background: #bb5191;
  }
  .scheme_violet .onlinetools_popup_overview .touch_link:hover .title {
    color: #bb5191;
  }
  .scheme_violet .highlight_keyword {
    background: #bb5191;
  }
  .scheme_violet .steuernews_video_overview .video_play .icon {
    fill: #bb5191;
  }
  .scheme_violet .steuernews_current_issue ul > li::after {
    background: #bb5191;
  }
  .scheme_violet .video_overview .video_play .icon,
  .scheme_violet .video_infolists_overview .video_play .icon,
  .scheme_violet .video_infolist_overview .video_play .icon,
  .scheme_violet .video_tutorial_overview .video_play .icon {
    fill: #bb5191;
  }
  .scheme_orange ::-moz-selection {
    background: #f0893e;
  }
  .scheme_orange ::selection {
    background: #f0893e;
  }
  .scheme_orange h1 {
    color: #f0893e;
  }
  .scheme_orange h2 {
    color: #f0893e;
  }
  .scheme_orange ol > li:before {
    color: #f0893e;
  }
  .scheme_orange ul > li::after {
    background: #f0893e;
  }
  .scheme_orange .button {
    background: #f0893e;
    border: 0.25rem solid #f0893e;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_orange .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_orange .button .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_orange .button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_orange .button .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_orange .button:focus {
    background: #f4a66d;
    border-color: #f4a66d;
    color: #ffffff;
  }
  .scheme_orange .button:focus .icon {
    fill: #ffffff;
  }
  .scheme_orange .button:hover {
    background: #f4a66d;
    border-color: #f4a66d;
    color: #111111;
  }
  .scheme_orange .button:hover .icon {
    fill: #111111;
  }
  .scheme_orange .button:active,
  .scheme_orange .button.active {
    background: #f0893e;
    border: 0.25rem solid #f0893e;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_orange .button:active > span,
  .scheme_orange .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_orange .button:active .icon,
  .scheme_orange .button.active .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_orange .button:active .text,
  .scheme_orange .button.active .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_orange .button:active .icon + .text,
  .scheme_orange .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_orange .bypass_blocks a {
    color: #f0893e;
  }
  .scheme_orange .bypass_blocks a:focus {
    color: #f0893e;
  }
  .scheme_orange [role="tab"][aria-expanded="true"] {
    border-color: #f0893e;
  }
  .scheme_orange [role="tab"]:focus,
  .scheme_orange [role="tab"]:hover {
    border-color: #f0893e;
  }
  .scheme_orange [role="tab"]:focus .title,
  .scheme_orange [role="tab"]:hover .title {
    color: #f0893e;
  }
  .scheme_orange .download_overview .entry:hover {
    border-color: #f0893e;
  }
  .scheme_orange .required_asterisk {
    color: #f0893e;
  }
  .scheme_orange .error_warning {
    background: #f0893e;
  }
  .scheme_orange .input[aria-invalid="true"],
  .scheme_orange .textarea[aria-invalid="true"] {
    border-color: #f0893e;
  }
  .scheme_orange .input[aria-invalid="true"]:focus,
  .scheme_orange .textarea[aria-invalid="true"]:focus {
    border-color: #f0893e;
    box-shadow: 0 0 0 0.75rem rgba(240, 137, 62, 0.2);
  }
  .scheme_orange .input:focus,
  .scheme_orange .textarea:focus {
    border-color: #f0893e;
    box-shadow: 0 0 0 0.75rem rgba(240, 137, 62, 0.2);
  }
  .scheme_orange .control input[aria-invalid="true"] ~ .indicator {
    border-color: #f0893e;
  }
  .scheme_orange .control input[aria-invalid="true"]:focus ~ .indicator {
    border-color: #f0893e;
    box-shadow: 0 0 0 0.75rem rgba(240, 137, 62, 0.2);
  }
  .scheme_orange .control input:focus ~ .indicator {
    border-color: #f0893e;
    box-shadow: 0 0 0 0.75rem rgba(240, 137, 62, 0.2);
  }
  .scheme_orange .select.error {
    border-color: #f0893e;
  }
  .scheme_orange .select.error.focus {
    border-color: #f0893e;
    box-shadow: 0 0 0 0.75rem rgba(240, 137, 62, 0.2);
  }
  .scheme_orange .select.focus {
    border-color: #f0893e;
    box-shadow: 0 0 0 0.75rem rgba(240, 137, 62, 0.2);
  }
  .scheme_orange .travel_mode input:checked ~ .travel_mode_radio {
    background: #f0893e;
    border-color: #f0893e;
    color: #ffffff;
  }
  .scheme_orange .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_orange .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.75rem rgba(240, 137, 62, 0.2);
    background: #f0893e;
    border-color: #f0893e;
    color: #ffffff;
  }
  .scheme_orange .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_orange .warnings {
    color: #f0893e;
  }
  .scheme_orange .header_wrapper {
    background: #f0893e;
  }
  .scheme_orange .vpi_diagram_grid_wrapper .vpi_diagram_legend circle,
  .scheme_orange .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    fill: #f0893e;
  }
  .scheme_orange .vpi_diagram_grid_wrapper .vpi_diagram_legend line,
  .scheme_orange .vpi_diagram_grid_wrapper .vpi_diagram_points line {
    stroke: #f0893e;
  }
  .scheme_orange .infolist_top_link .icon {
    fill: #f0893e;
  }
  .scheme_orange .infolist_overview .entry:hover,
  .scheme_orange .overview .entry:hover,
  .scheme_orange .useful_link_overview .entry:hover {
    border-left: 0.25rem solid #f0893e;
  }
  .scheme_orange .infolist_overview .entry:hover .title,
  .scheme_orange .overview .entry:hover .title,
  .scheme_orange .useful_link_overview .entry:hover .title {
    color: #f0893e;
  }
  .scheme_orange .infolist_link_to_news:hover {
    border-left: 0.25rem solid #f0893e;
  }
  .scheme_orange .infolist_link_to_news:hover .title {
    color: #f0893e;
  }
  .scheme_orange .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line,
  .scheme_orange .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line {
    background: #f0893e;
  }
  .scheme_orange .offcanvas_menu .offcanvas_menu_content > ul > li > a:active {
    color: #f0893e;
  }
  .scheme_orange .offcanvas_menu .offcanvas_menu_content > ul li.expanded > a {
    color: #f0893e;
  }
  .scheme_orange .offcanvas_menu .offcanvas_menu_content > ul ul a {
    border-right: 0.25rem solid #f0893e;
  }
  .scheme_orange .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #f0893e;
  }
  .scheme_orange .popup_close {
    background: #f0893e;
  }
  .scheme_orange .onlinetools_popup_overview .touch_link:hover .title {
    color: #f0893e;
  }
  .scheme_orange .highlight_keyword {
    background: #f0893e;
  }
  .scheme_orange .steuernews_video_overview .video_play .icon {
    fill: #f0893e;
  }
  .scheme_orange .steuernews_current_issue ul > li::after {
    background: #f0893e;
  }
  .scheme_orange .video_overview .video_play .icon,
  .scheme_orange .video_infolists_overview .video_play .icon,
  .scheme_orange .video_infolist_overview .video_play .icon,
  .scheme_orange .video_tutorial_overview .video_play .icon {
    fill: #f0893e;
  }
  .scheme_green ::-moz-selection {
    background: #0da193;
  }
  .scheme_green ::selection {
    background: #0da193;
  }
  .scheme_green h1 {
    color: #0da193;
  }
  .scheme_green h2 {
    color: #0da193;
  }
  .scheme_green ol > li:before {
    color: #0da193;
  }
  .scheme_green ul > li::after {
    background: #0da193;
  }
  .scheme_green .button {
    background: #0da193;
    border: 0.25rem solid #0da193;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_green .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_green .button .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_green .button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_green .button .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_green .button:focus {
    background: #11d0be;
    border-color: #11d0be;
    color: #ffffff;
  }
  .scheme_green .button:focus .icon {
    fill: #ffffff;
  }
  .scheme_green .button:hover {
    background: #11d0be;
    border-color: #11d0be;
    color: #111111;
  }
  .scheme_green .button:hover .icon {
    fill: #111111;
  }
  .scheme_green .button:active,
  .scheme_green .button.active {
    background: #0da193;
    border: 0.25rem solid #0da193;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_green .button:active > span,
  .scheme_green .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_green .button:active .icon,
  .scheme_green .button.active .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_green .button:active .text,
  .scheme_green .button.active .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_green .button:active .icon + .text,
  .scheme_green .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_green .bypass_blocks a {
    color: #0da193;
  }
  .scheme_green .bypass_blocks a:focus {
    color: #0da193;
  }
  .scheme_green [role="tab"][aria-expanded="true"] {
    border-color: #0da193;
  }
  .scheme_green [role="tab"]:focus,
  .scheme_green [role="tab"]:hover {
    border-color: #0da193;
  }
  .scheme_green [role="tab"]:focus .title,
  .scheme_green [role="tab"]:hover .title {
    color: #0da193;
  }
  .scheme_green .download_overview .entry:hover {
    border-color: #0da193;
  }
  .scheme_green .required_asterisk {
    color: #0da193;
  }
  .scheme_green .error_warning {
    background: #0da193;
  }
  .scheme_green .input[aria-invalid="true"],
  .scheme_green .textarea[aria-invalid="true"] {
    border-color: #0da193;
  }
  .scheme_green .input[aria-invalid="true"]:focus,
  .scheme_green .textarea[aria-invalid="true"]:focus {
    border-color: #0da193;
    box-shadow: 0 0 0 0.75rem rgba(13, 161, 147, 0.2);
  }
  .scheme_green .input:focus,
  .scheme_green .textarea:focus {
    border-color: #0da193;
    box-shadow: 0 0 0 0.75rem rgba(13, 161, 147, 0.2);
  }
  .scheme_green .control input[aria-invalid="true"] ~ .indicator {
    border-color: #0da193;
  }
  .scheme_green .control input[aria-invalid="true"]:focus ~ .indicator {
    border-color: #0da193;
    box-shadow: 0 0 0 0.75rem rgba(13, 161, 147, 0.2);
  }
  .scheme_green .control input:focus ~ .indicator {
    border-color: #0da193;
    box-shadow: 0 0 0 0.75rem rgba(13, 161, 147, 0.2);
  }
  .scheme_green .select.error {
    border-color: #0da193;
  }
  .scheme_green .select.error.focus {
    border-color: #0da193;
    box-shadow: 0 0 0 0.75rem rgba(13, 161, 147, 0.2);
  }
  .scheme_green .select.focus {
    border-color: #0da193;
    box-shadow: 0 0 0 0.75rem rgba(13, 161, 147, 0.2);
  }
  .scheme_green .travel_mode input:checked ~ .travel_mode_radio {
    background: #0da193;
    border-color: #0da193;
    color: #ffffff;
  }
  .scheme_green .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_green .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.75rem rgba(13, 161, 147, 0.2);
    background: #0da193;
    border-color: #0da193;
    color: #ffffff;
  }
  .scheme_green .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_green .warnings {
    color: #0da193;
  }
  .scheme_green .header_wrapper {
    background: #0da193;
  }
  .scheme_green .vpi_diagram_grid_wrapper .vpi_diagram_legend circle,
  .scheme_green .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    fill: #0da193;
  }
  .scheme_green .vpi_diagram_grid_wrapper .vpi_diagram_legend line,
  .scheme_green .vpi_diagram_grid_wrapper .vpi_diagram_points line {
    stroke: #0da193;
  }
  .scheme_green .infolist_top_link .icon {
    fill: #0da193;
  }
  .scheme_green .infolist_overview .entry:hover,
  .scheme_green .overview .entry:hover,
  .scheme_green .useful_link_overview .entry:hover {
    border-left: 0.25rem solid #0da193;
  }
  .scheme_green .infolist_overview .entry:hover .title,
  .scheme_green .overview .entry:hover .title,
  .scheme_green .useful_link_overview .entry:hover .title {
    color: #0da193;
  }
  .scheme_green .infolist_link_to_news:hover {
    border-left: 0.25rem solid #0da193;
  }
  .scheme_green .infolist_link_to_news:hover .title {
    color: #0da193;
  }
  .scheme_green .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line,
  .scheme_green .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line {
    background: #0da193;
  }
  .scheme_green .offcanvas_menu .offcanvas_menu_content > ul > li > a:active {
    color: #0da193;
  }
  .scheme_green .offcanvas_menu .offcanvas_menu_content > ul li.expanded > a {
    color: #0da193;
  }
  .scheme_green .offcanvas_menu .offcanvas_menu_content > ul ul a {
    border-right: 0.25rem solid #0da193;
  }
  .scheme_green .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #0da193;
  }
  .scheme_green .popup_close {
    background: #0da193;
  }
  .scheme_green .onlinetools_popup_overview .touch_link:hover .title {
    color: #0da193;
  }
  .scheme_green .highlight_keyword {
    background: #0da193;
  }
  .scheme_green .steuernews_video_overview .video_play .icon {
    fill: #0da193;
  }
  .scheme_green .steuernews_current_issue ul > li::after {
    background: #0da193;
  }
  .scheme_green .video_overview .video_play .icon,
  .scheme_green .video_infolists_overview .video_play .icon,
  .scheme_green .video_infolist_overview .video_play .icon,
  .scheme_green .video_tutorial_overview .video_play .icon {
    fill: #0da193;
  }
  .scheme_blue ::-moz-selection {
    background: #7599cc;
  }
  .scheme_blue ::selection {
    background: #7599cc;
  }
  .scheme_blue h1 {
    color: #7599cc;
  }
  .scheme_blue h2 {
    color: #7599cc;
  }
  .scheme_blue ol > li:before {
    color: #7599cc;
  }
  .scheme_blue ul > li::after {
    background: #7599cc;
  }
  .scheme_blue .button {
    background: #7599cc;
    border: 0.25rem solid #7599cc;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_blue .button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_blue .button .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_blue .button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_blue .button .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_blue .button:focus {
    background: #9ab5da;
    border-color: #9ab5da;
    color: #ffffff;
  }
  .scheme_blue .button:focus .icon {
    fill: #ffffff;
  }
  .scheme_blue .button:hover {
    background: #9ab5da;
    border-color: #9ab5da;
    color: #111111;
  }
  .scheme_blue .button:hover .icon {
    fill: #111111;
  }
  .scheme_blue .button:active,
  .scheme_blue .button.active {
    background: #7599cc;
    border: 0.25rem solid #7599cc;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .scheme_blue .button:active > span,
  .scheme_blue .button.active > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .scheme_blue .button:active .icon,
  .scheme_blue .button.active .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .scheme_blue .button:active .text,
  .scheme_blue .button.active .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .scheme_blue .button:active .icon + .text,
  .scheme_blue .button.active .icon + .text {
    padding-left: 0.5em;
  }
  .scheme_blue .bypass_blocks a {
    color: #7599cc;
  }
  .scheme_blue .bypass_blocks a:focus {
    color: #7599cc;
  }
  .scheme_blue [role="tab"][aria-expanded="true"] {
    border-color: #7599cc;
  }
  .scheme_blue [role="tab"]:focus,
  .scheme_blue [role="tab"]:hover {
    border-color: #7599cc;
  }
  .scheme_blue [role="tab"]:focus .title,
  .scheme_blue [role="tab"]:hover .title {
    color: #7599cc;
  }
  .scheme_blue .download_overview .entry:hover {
    border-color: #7599cc;
  }
  .scheme_blue .required_asterisk {
    color: #7599cc;
  }
  .scheme_blue .error_warning {
    background: #7599cc;
  }
  .scheme_blue .input[aria-invalid="true"],
  .scheme_blue .textarea[aria-invalid="true"] {
    border-color: #7599cc;
  }
  .scheme_blue .input[aria-invalid="true"]:focus,
  .scheme_blue .textarea[aria-invalid="true"]:focus {
    border-color: #7599cc;
    box-shadow: 0 0 0 0.75rem rgba(117, 153, 204, 0.2);
  }
  .scheme_blue .input:focus,
  .scheme_blue .textarea:focus {
    border-color: #7599cc;
    box-shadow: 0 0 0 0.75rem rgba(117, 153, 204, 0.2);
  }
  .scheme_blue .control input[aria-invalid="true"] ~ .indicator {
    border-color: #7599cc;
  }
  .scheme_blue .control input[aria-invalid="true"]:focus ~ .indicator {
    border-color: #7599cc;
    box-shadow: 0 0 0 0.75rem rgba(117, 153, 204, 0.2);
  }
  .scheme_blue .control input:focus ~ .indicator {
    border-color: #7599cc;
    box-shadow: 0 0 0 0.75rem rgba(117, 153, 204, 0.2);
  }
  .scheme_blue .select.error {
    border-color: #7599cc;
  }
  .scheme_blue .select.error.focus {
    border-color: #7599cc;
    box-shadow: 0 0 0 0.75rem rgba(117, 153, 204, 0.2);
  }
  .scheme_blue .select.focus {
    border-color: #7599cc;
    box-shadow: 0 0 0 0.75rem rgba(117, 153, 204, 0.2);
  }
  .scheme_blue .travel_mode input:checked ~ .travel_mode_radio {
    background: #7599cc;
    border-color: #7599cc;
    color: #ffffff;
  }
  .scheme_blue .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_blue .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.75rem rgba(117, 153, 204, 0.2);
    background: #7599cc;
    border-color: #7599cc;
    color: #ffffff;
  }
  .scheme_blue .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .scheme_blue .warnings {
    color: #7599cc;
  }
  .scheme_blue .header_wrapper {
    background: #7599cc;
  }
  .scheme_blue .vpi_diagram_grid_wrapper .vpi_diagram_legend circle,
  .scheme_blue .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    fill: #7599cc;
  }
  .scheme_blue .vpi_diagram_grid_wrapper .vpi_diagram_legend line,
  .scheme_blue .vpi_diagram_grid_wrapper .vpi_diagram_points line {
    stroke: #7599cc;
  }
  .scheme_blue .infolist_top_link .icon {
    fill: #7599cc;
  }
  .scheme_blue .infolist_overview .entry:hover,
  .scheme_blue .overview .entry:hover,
  .scheme_blue .useful_link_overview .entry:hover {
    border-left: 0.25rem solid #7599cc;
  }
  .scheme_blue .infolist_overview .entry:hover .title,
  .scheme_blue .overview .entry:hover .title,
  .scheme_blue .useful_link_overview .entry:hover .title {
    color: #7599cc;
  }
  .scheme_blue .infolist_link_to_news:hover {
    border-left: 0.25rem solid #7599cc;
  }
  .scheme_blue .infolist_link_to_news:hover .title {
    color: #7599cc;
  }
  .scheme_blue .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line,
  .scheme_blue .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line {
    background: #7599cc;
  }
  .scheme_blue .offcanvas_menu .offcanvas_menu_content > ul > li > a:active {
    color: #7599cc;
  }
  .scheme_blue .offcanvas_menu .offcanvas_menu_content > ul li.expanded > a {
    color: #7599cc;
  }
  .scheme_blue .offcanvas_menu .offcanvas_menu_content > ul ul a {
    border-right: 0.25rem solid #7599cc;
  }
  .scheme_blue .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #7599cc;
  }
  .scheme_blue .popup_close {
    background: #7599cc;
  }
  .scheme_blue .onlinetools_popup_overview .touch_link:hover .title {
    color: #7599cc;
  }
  .scheme_blue .highlight_keyword {
    background: #7599cc;
  }
  .scheme_blue .steuernews_video_overview .video_play .icon {
    fill: #7599cc;
  }
  .scheme_blue .steuernews_current_issue ul > li::after {
    background: #7599cc;
  }
  .scheme_blue .video_overview .video_play .icon,
  .scheme_blue .video_infolists_overview .video_play .icon,
  .scheme_blue .video_infolist_overview .video_play .icon,
  .scheme_blue .video_tutorial_overview .video_play .icon {
    fill: #7599cc;
  }
  .startpage h1 {
    letter-spacing: inherit;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    margin: inherit;
    margin-top: inherit;
    margin-right: inherit;
    text-transform: inherit;
  }
  .company_logo {
    color: #ffffff;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-left: 1.5em;
    text-decoration: none;
  }
  .company_logo .company_logo_name {
    letter-spacing: calc((2.0625rem + 1.1875
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 2.0625rem;
    font-weight: 300;
    letter-spacing: 0.1875rem;
    line-height: 2.75rem;
    position: relative;
    text-transform: uppercase;
  }
  .company_logo .logo_border {
    background: #ffffff;
    bottom: 0.625rem;
    left: -1.5rem;
    position: absolute;
    top: 0.75rem;
    width: 0.25rem;
  }
  .company_logo .company_logo_addition {
    letter-spacing: calc((0.75rem + 0.25
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 0.75rem;
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
    letter-spacing: 0.1rem;
    line-height: 1.25rem;
    margin-right: 4.0625rem;
    margin-top: 0.5625rem;
  }
  .startpage .company_logo {
    color: #111111;
  }
  .startpage .logo_border {
    background: #111111;
  }
  .cookie_banner {
    -webkit-box-align: center;
            align-items: center;
    background: #f7f7f7;
    bottom: 0;
    display: -webkit-box;
    display: flex;
    left: 0;
    position: fixed;
    right: 0;
    z-index: 9000;
  }
  .cookie_banner p {
    flex-basis: 100%;
    margin: 0;
    padding: 1em 0.75em 1em 1.5em;
  }
  .cookie_banner .close {
    cursor: pointer;
    margin-right: 0.75em;
    padding: 0 0.75em;
  }
  .cookie_banner .close > span {
    min-height: 2.25rem;
  }
  .cookie_banner .close .icon svg {
    fill: #111111;
    height: 2.75rem;
    width: 2.75rem;
  }
  .download_overview {
    margin-bottom: 0.75em;
  }
  .download_overview .entry {
    border-left: 0.25rem solid #eaeaea;
    margin-bottom: 0.75em;
    padding-left: 1.5em;
  }
  .download_overview .entry .title {
    margin: 0;
    padding: 0;
    text-decoration: none;
  }
  .download_overview .entry:hover {
    border-color: #eb6c5f;
  }
  footer {
    margin: 1.5em 0;
  }
  .related_links ul {
    display: -webkit-box;
    display: flex;
  }
  .related_links ul li {
    margin-right: 1.5em;
  }
  .related_links ul a {
    text-decoration: none;
  }
  .related_links ul a:hover,
  .related_links ul a:focus,
  .related_links ul a.active {
    text-decoration: underline;
  }
  .startpage footer {
    background: white;
    padding-bottom: 2rem;
    padding-top: 2rem;
  }
  .form_disclaimer {
    background: #f7f7f7;
    border-radius: 0;
    color: #111111;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .file {
    display: -webkit-box;
    display: flex;
    flex-basis: 65%;
    margin: 0 0 1em;
    position: relative;
    height: 2.75rem;
  }
  .file.error .file_name {
    border-color: #eb6c5f;
  }
  .file .file_name {
    background: #ffffff;
    border: 0.25rem solid #eaeaea;
    border-radius: 0 0 0 0;
    box-shadow: 0 0 0.75rem #eaeaea inset;
    color: #111111;
    -webkit-box-flex: 0;
            flex: 0 1 100%;
    overflow: hidden;
    padding: 0 0.75em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 8.125rem;
    line-height: 2.25rem;
    height: 2.75rem;
  }
  .file .file_button {
    border-radius: 0 0 0 0;
    -webkit-box-flex: 1;
            flex: 1 0 auto;
    padding: 0 1.5em;
    z-index: 10;
    background: #eb6c5f;
    border: 0.25rem solid #eb6c5f;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .file .file_button .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .file .file_button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .file .file_button .icon + .text {
    padding-left: 0.5em;
  }
  .file .file_button.focus {
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
    background: #f1968c;
    border-color: #f1968c;
    color: #ffffff;
  }
  .file .file_button.focus .icon {
    fill: #ffffff;
  }
  .file [type="file"] {
    bottom: 0;
    color: transparent;
    cursor: pointer;
    display: block;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 20;
    height: 2.75rem;
  }
  .file [type="file"]:hover ~ .file_button {
    background: #f1968c;
    border-color: #f1968c;
    color: #111111;
  }
  .file [type="file"]:hover ~ .file_button .icon {
    fill: #111111;
  }
  .file [type="file"]:active ~ .file_button {
    box-shadow: 0 0 0.75rem #eb6c5f inset;
    background: #eb6c5f;
    border: 0.25rem solid #eb6c5f;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .file [type="file"]:active ~ .file_button > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .file [type="file"]:active ~ .file_button .icon {
    fill: #ffffff;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .file [type="file"]:active ~ .file_button .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .file [type="file"]:active ~ .file_button .icon + .text {
    padding-left: 0.5em;
  }
  button {
    cursor: pointer;
    margin: 0.75em 0;
    outline: 0;
    overflow: hidden;
    padding: 0 1.5em;
    width: 100%;
    height: 2.75rem;
  }
  button::-moz-focus-inner {
    border: 0;
  }
  .google_maps {
    margin: 0 0 0.75em;
  }
  .google_maps .map {
    background: #f7f7f7;
    height: 50vh;
    min-height: 25rem;
  }
  .google_maps .map img {
    max-width: none;
  }
  .google_maps .map .gm-style > button {
    width: auto;
  }
  .google_maps .map_print {
    display: none;
  }
  .google_maps .travel_mode {
    display: -webkit-box;
    display: flex;
    margin: 0 0 0.75em;
  }
  .google_maps .travel_mode input {
    opacity: 0;
    position: absolute;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio {
    z-index: 5;
    background: #eb6c5f;
    border-color: #eb6c5f;
    color: #ffffff;
  }
  .google_maps .travel_mode input:checked ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio {
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
    z-index: 15;
    background: #f1968c;
    border-color: #f1968c;
    color: #ffffff;
  }
  .google_maps .travel_mode input:focus ~ .travel_mode_radio .icon {
    fill: #ffffff;
  }
  .google_maps .travel_mode_entry {
    flex-basis: 100%;
    height: 2.75rem;
  }
  .google_maps .travel_mode_radio {
    cursor: pointer;
    position: relative;
    width: 100%;
    background: #ffffff;
    border: 0.25rem solid #eaeaea;
    color: #111111;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
            justify-content: center;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .google_maps .travel_mode_radio > span {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    min-height: 2.25rem;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #111111;
    flex-shrink: 0;
    height: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
    width: 1.5rem;
  }
  .google_maps .travel_mode_radio .text {
    font-weight: 400;
    padding: 0.375em 0;
  }
  .google_maps .travel_mode_radio .icon + .text {
    padding-left: 0.5em;
  }
  .google_maps .travel_mode_radio .icon {
    fill: #111111;
    min-width: 1.5rem;
    -webkit-transition: fill 600ms;
    transition: fill 600ms;
  }
  .google_maps .travel_mode_radio.driving {
    border-radius: 0 0 0 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.transit,
  .google_maps .travel_mode_radio.bicycling {
    border-left-width: 0;
    border-right-width: 0;
  }
  .google_maps .travel_mode_radio.walking {
    border-left-width: 0;
    border-radius: 0 0 0 0;
  }
  .google_maps .warnings {
    background: #f6ecec;
    border-radius: 0;
    color: #eb6c5f;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .google_maps .direction {
    margin: 0.75em 0;
  }
  .google_maps .button_wrapper {
    margin-top: auto;
  }
  .google_maps .panel_wrapper {
    padding-top: 0.75em;
  }
  .google_maps .steps {
    min-width: 100%;
  }
  .google_maps .step {
    border-top: 0.25rem solid #eaeaea;
    display: -webkit-box;
    display: flex;
  }
  .google_maps .step.last {
    border-bottom: 0.25rem solid #eaeaea;
  }
  .google_maps .step:before {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions,
  .google_maps .distance {
    font-size: 85%;
    padding: 0.75em 0;
  }
  .google_maps .instructions {
    overflow: hidden;
    padding-left: 0.75em;
    width: 100%;
    word-wrap: break-word;
  }
  .google_maps .distance {
    min-width: 5rem;
    text-align: right;
  }
  .google_maps .infowindow {
    max-width: 13.75rem;
  }
  .container {
    margin: 0 auto;
    max-width: 100rem;
    min-width: 20rem;
    padding: 0 0.9375rem;
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.mobile .container {
      padding-left: max(0.9375rem, env(safe-area-inset-left));
      padding-right: max(0.9375rem, env(safe-area-inset-right));
    }
  }
  .row {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    flex-wrap: wrap;
    margin-left: -0.46875rem;
    margin-right: -0.46875rem;
  }
  .row.around_xsmall {
    justify-content: space-around;
  }
  .row.between_xsmall {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xsmall {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xsmall {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xsmall {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xsmall {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xsmall {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xsmall {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xsmall {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
  .row.no_column_margin {
    margin-left: 0;
    margin-right: 0;
  }
  .row.no_column_margin > [class*="span"] {
    padding-left: 0;
    padding-right: 0;
  }
  .row.half_column_margin {
    margin-left: -0.234375rem;
    margin-right: -0.234375rem;
  }
  .row.half_column_margin > [class*="span"] {
    padding-left: 0.234375rem;
    padding-right: 0.234375rem;
  }
  [class*="span"] {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 0.46875rem;
    padding-right: 0.46875rem;
  }
  [class*="span"].span_column {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .span1_xsmall {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xsmall {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xsmall {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xsmall {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xsmall {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xsmall {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xsmall {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xsmall {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xsmall {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xsmall {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xsmall {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xsmall {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xsmall {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xsmall {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xsmall {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xsmall {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xsmall {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xsmall {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xsmall {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xsmall {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xsmall {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xsmall {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xsmall {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xsmall {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
  .header_background {
    background-position: center center;
    background-size: cover;
    margin: 0 auto;
    overflow: hidden;
    padding-bottom: 18.75rem;
  }
  .header_content {
    font-size: 85%;
    margin-top: 1.5em;
    max-width: 20rem;
  }
  .header_wrapper {
    background: #eb6c5f;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    padding: 1.125rem 0 1.375rem;
    width: 100%;
  }
  .header_wrapper .button_login {
    background: none;
    border: 0.25rem solid #111111;
    margin-top: 1.5em;
  }
  .header_wrapper .button_login:hover {
    background: #111111;
    border-color: #111111;
  }
  .header_wrapper .button_login:hover svg {
    fill: #ffffff;
  }
  .header_wrapper .button_login svg {
    fill: #111111;
  }
  .header_wrapper .button_login .text {
    color: #111111;
    font-weight: bold;
  }
  .header_wrapper .call {
    background: none;
    border: 0.25rem solid #111111;
    margin-top: 1.5em;
  }
  .header_wrapper .call:hover {
    background: #111111;
    border-color: #111111;
  }
  .header_wrapper .call:hover svg {
    fill: #ffffff;
  }
  .header_wrapper .call svg {
    fill: #111111;
  }
  .startpage .header_wrapper {
    background: none;
  }
  .label_equalita {
    margin: 1.2em 0 0;
  }
  .sportverein_wrapper .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
    -webkit-box-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
            align-items: center;
    margin-top: 1.5em;
  }
  .sportverein_wrapper .entry .logo_sportverein {
    margin: 0 0.75em 0 0 !important;
  }
  .hideme_mobile {
    display: none;
  }
  body.verbraucherpreisindizes .switch_view {
    cursor: pointer;
    margin-bottom: 0.75em;
  }
  .vpi_diagram_grid_wrapper {
    border: 0.25rem solid #eaeaea;
    margin-bottom: 1.5em;
    padding: 0.75em 1.5em 0.75em 0.75em;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram {
    line-height: 1;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid_points_wrapper {
    position: relative;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_label_legend_wrapper {
    padding: 0.5em 0;
    position: relative;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_year_label {
    line-height: 1;
    margin: 0;
    padding: 0;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid {
    height: 18.75rem;
    padding: 0.75em 0 0.75em 0;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid line {
    stroke: #bbbbbb;
    stroke-width: 0.03125rem;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_grid text {
    text-anchor: end;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_points {
    height: 18.75rem;
    left: 0;
    padding: 0.75em 0 0.75em 3.75rem;
    position: absolute;
    right: 2.25em;
    top: 0;
    z-index: 1;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    cursor: pointer;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend circle,
  .vpi_diagram_grid_wrapper .vpi_diagram_points circle {
    fill: #eb6c5f;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend line,
  .vpi_diagram_grid_wrapper .vpi_diagram_points line {
    stroke: #eb6c5f;
    stroke-width: 0.125rem;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend {
    height: 2.5rem;
    margin-top: -1.25rem;
    overflow-x: hidden;
    position: absolute;
    right: 0;
    top: 50%;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_legend text {
    text-anchor: end;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_months_wrapper {
    height: 3.125rem;
    margin-left: 3.75rem;
    position: relative;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_months {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_months text {
    text-anchor: middle;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output_wrapper {
    bottom: 0.75em;
    left: 0;
    margin-left: 3.75rem;
    position: absolute;
    right: 0;
    top: 0.75em;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output {
    background-color: #ffffff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    opacity: 0;
    padding: 1.05em;
    position: absolute;
    -webkit-transform: translateX(-50%) translateY(-120%);
            transform: translateX(-50%) translateY(-120%);
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    z-index: 2;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output.show {
    opacity: 1;
    visibility: visible;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output.right {
    -webkit-transform: translateX(-90%) translateY(-130%);
            transform: translateX(-90%) translateY(-130%);
  }
  .vpi_diagram_grid_wrapper .vpi_diagram_output .value {
    margin-bottom: 0;
    white-space: nowrap;
  }
  .vpi_diagram_grid_wrapper .vpi_diagram text {
    font-size: 85%;
  }
  .map_chart {
    height: 16.25rem;
    background-color: #ffffff;
    margin: 0 0 1.5em 0;
    max-width: 100%;
    position: relative;
  }
  .map_chart .drag_area {
    bottom: 0;
    left: 3.75rem;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 0;
    visibility: hidden;
    z-index: 1;
  }
  .map_chart .drag_area.visible {
    visibility: visible;
  }
  .map_chart .map_chart_wrapper {
    height: 16.25rem;
    border: 0.25rem solid #eaeaea;
    cursor: move;
    overflow: hidden;
    padding: 0.375em;
    position: relative;
    z-index: 0;
  }
  .map_chart svg path {
    position: relative;
    stroke: #ffffff;
    -webkit-transition: fill-opacity 600ms;
    transition: fill-opacity 600ms;
  }
  .map_chart svg path.hasData {
    fill: #eb6c5f;
  }
  .map_chart svg path.noData {
    fill: #dddddd;
  }
  .map_chart .map_chart_navigation {
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    left: 0.75em;
    position: absolute;
    top: 0.75em;
  }
  .map_chart .map_chart_navigation .zoom {
    -webkit-box-align: center;
            align-items: center;
    background-color: #f7f7f7;
    border: 0.25rem solid #eaeaea;
    border-radius: 0;
    color: #111111;
    cursor: pointer;
    display: -webkit-box;
    display: flex;
    font-size: 120%;
    height: 2.5rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0 0 0.375em 0;
    padding: 0.375em 0.75em;
    width: 2.5rem;
  }
  .map_chart .map_chart_navigation .zoom.disabled {
    cursor: default;
    opacity: 0.4;
  }
  .map_chart .output {
    background-color: #ffffff;
    box-shadow: 0 0 0.75rem rgba(0, 0, 0, 0.2);
    min-width: 9.375rem;
    opacity: 0;
    padding: 1.05em;
    position: absolute;
    -webkit-transition: left 600ms, opacity 600ms, top 600ms, visibility 600ms;
    transition: left 600ms, opacity 600ms, top 600ms, visibility 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    visibility: hidden;
    z-index: 2;
  }
  .map_chart .output.show {
    opacity: 1;
    visibility: visible;
  }
  .map_chart .touch_info {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(0, 0, 0, 0.1);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    font-weight: 700;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    z-index: 3;
  }
  body.reisekosten .switch_view {
    margin-bottom: 0.75em;
  }
  .infolist_anchor_overview {
    background: #f7f7f7;
    border-radius: 0;
    padding: 0.75em 1.5em;
  }
  .infolist_anchor_overview a {
    display: block;
    padding: 0.75em 0;
  }
  .infolist_anchor_overview li {
    border-top: 0.25rem solid #eaeaea;
  }
  .infolist_anchor_overview li:first-child {
    border-top: 0;
  }
  .amount {
    display: block;
    text-align: right;
    white-space: nowrap;
  }
  .steuerlexikon .amount {
    display: table-cell;
  }
  .multi_columns li {
    -webkit-column-break-inside: avoid;
            break-inside: avoid;
  }
  .infolist_top_link {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin: 0.75em 0;
  }
  .infolist_top_link a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    text-align: center;
  }
  .infolist_top_link .icon {
    display: block;
    fill: #eb6c5f;
    height: 3rem;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    width: 3rem;
  }
  .disclaimer {
    background: #f7f7f7;
    border-radius: 0;
    color: #111111;
    font-size: 85%;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .infolist_object_boxit_sicherer_klienten_login {
    display: none;
  }
  .infolist_download {
    -webkit-box-align: baseline;
            align-items: baseline;
    background: #f7f7f7;
    border-radius: 0;
    color: #111111;
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
            justify-content: space-between;
    margin-bottom: 1.5em;
    padding: 1.5em;
  }
  .infolist_download .infolist_title {
    font-weight: 700;
  }
  .infolist_download p {
    margin-right: 1.5em;
  }
  .infolist_overview .entry,
  .overview .entry,
  .useful_link_overview .entry {
    background: #f7f7f7;
    border-left: 0.25rem solid #eaeaea;
    margin-bottom: 0.75em;
    padding: 0.75em 1.5em;
    -webkit-transition: border 600ms ease;
    transition: border 600ms ease;
  }
  .infolist_overview .entry .title,
  .overview .entry .title,
  .useful_link_overview .entry .title {
    color: #111111;
    text-decoration: none;
  }
  .infolist_overview .entry .text,
  .overview .entry .text,
  .useful_link_overview .entry .text {
    margin: 0;
  }
  .infolist_overview .entry:hover,
  .overview .entry:hover,
  .useful_link_overview .entry:hover {
    border-left: 0.25rem solid #eb6c5f;
    -webkit-transition: border 600ms ease;
    transition: border 600ms ease;
  }
  .infolist_overview .entry:hover .title,
  .overview .entry:hover .title,
  .useful_link_overview .entry:hover .title {
    color: #eb6c5f;
  }
  .infolist_link_to_news {
    background: #f7f7f7;
    border-left: 0.25rem solid #eaeaea;
    display: block;
    margin: 0.75em 0;
    padding: 0.75em 1.5em;
    text-decoration: none;
    -webkit-transition: border 600ms ease;
    transition: border 600ms ease;
  }
  .infolist_link_to_news .title {
    color: #111111;
    text-decoration: none;
  }
  .infolist_link_to_news .text {
    margin: 0;
  }
  .infolist_link_to_news:hover {
    border-left: 0.25rem solid #eb6c5f;
    -webkit-transition: border 600ms ease;
    transition: border 600ms ease;
  }
  .infolist_link_to_news:hover .title {
    color: #eb6c5f;
  }
  .legal_notice_tax_aut_aab {
    margin-bottom: 0.75em;
    padding-top: 0.75em;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] {
    display: block;
  }
  .legal_notice_tax_aut_aab [href$=".pdf"] > span {
    display: -webkit-box;
    display: flex;
  }
  .legal_notice_tax_aut_aab .icon {
    display: block;
    fill: #e44e4e;
    height: 1.5rem;
    margin-right: 0.6em;
    min-width: 1.5rem;
    width: 1.5rem;
  }
  .legal_notice_font_overview p {
    margin-bottom: 0;
  }
  .legal_notice_font_overview li {
    margin-bottom: 0.75em;
  }
  .left_column {
    overflow: hidden;
  }
  .main_wrapper {
    width: calc(100vw - 75px);
  }
  .main_offset {
    padding: 3em 0 3em 1.5em;
  }
  .teilnahmebedingungen li {
    padding-left: 1.95em;
  }
  .partner {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-align: center;
            align-items: center;
    text-decoration: none;
  }
  .partner:hover,
  .partner:focus {
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: -webkit-box!important;
    display: flex!important;
    flex-wrap: wrap;
    height: 0;
    margin-bottom: 0;
    overflow: hidden;
    -webkit-transition: height 1200ms;
    transition: height 1200ms;
  }
  .toggle_offcanvas_menu {
    display: inline-block;
    margin: 0 0.3125rem;
    position: relative;
  }
  .toggle_offcanvas_menu label {
    cursor: pointer;
    font-weight: 700;
    height: 2.75rem;
    position: relative;
    text-transform: uppercase;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 10;
  }
  .toggle_offcanvas_menu label .text {
    -webkit-hyphens: none;
        -ms-hyphens: none;
            hyphens: none;
  }
  .toggle_offcanvas_menu label .icon {
    display: block;
    height: 2.75rem;
    position: relative;
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
    -webkit-transition: -webkit-transform 350ms 150ms;
    transition: -webkit-transform 350ms 150ms;
    transition: transform 350ms 150ms;
    transition: transform 350ms 150ms, -webkit-transform 350ms 150ms;
    width: 2.75rem;
  }
  .toggle_offcanvas_menu label .line {
    background: #111111;
    display: block;
    height: 0.25rem;
    position: absolute;
    width: 100%;
  }
  .toggle_offcanvas_menu label .line_1 {
    opacity: 1;
    top: 0.625rem;
    -webkit-transition: top 600ms 600ms, -webkit-transform 600ms;
    transition: top 600ms 600ms, -webkit-transform 600ms;
    transition: top 600ms 600ms, transform 600ms;
    transition: top 600ms 600ms, transform 600ms, -webkit-transform 600ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_2 {
    opacity: 1;
    top: 1.25rem;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    visibility: visible;
  }
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.625rem;
    -webkit-transition: bottom 600ms 600ms, -webkit-transform 600ms;
    transition: bottom 600ms 600ms, -webkit-transform 600ms;
    transition: bottom 600ms 600ms, transform 600ms;
    transition: bottom 600ms 600ms, transform 600ms, -webkit-transform 600ms;
  }
  .toggle_offcanvas_menu a {
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
  .toggle_offcanvas_menu .open_offcanvas_menu {
    display: block;
  }
  .toggle_offcanvas_menu .close_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 480ms, visibility 480ms;
    transition: opacity 480ms, visibility 480ms;
    visibility: hidden;
    z-index: 110;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    box-shadow: -0.3125rem 0rem 0.9375rem 0.4375rem rgba(0, 0, 0, 0.2);
    opacity: 1;
    padding-left: 1.5em;
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transition: width 1200ms ease, box-shadow 2400ms ease;
    transition: width 1200ms ease, box-shadow 2400ms ease;
    visibility: visible;
    width: 100vw;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_content,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_content {
    margin-left: 0;
    opacity: 1;
    -webkit-transition: margin 1800ms ease, opacity 1800ms ease;
    transition: margin 1800ms ease, opacity 1800ms ease;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu_overlay {
    opacity: 1;
    visibility: visible;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .icon {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: -webkit-transform 600ms;
    transition: -webkit-transform 600ms;
    transition: transform 600ms;
    transition: transform 600ms, -webkit-transform 600ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line {
    background: #eb6c5f;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_1 {
    top: 1.25rem;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: top 600ms, -webkit-transform 600ms 600ms;
    transition: top 600ms, -webkit-transform 600ms 600ms;
    transition: top 600ms, transform 600ms 600ms;
    transition: top 600ms, transform 600ms 600ms, -webkit-transform 600ms 600ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_2 {
    opacity: 0;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    visibility: hidden;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .line_3 {
    bottom: 1.25rem;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: bottom 600ms, -webkit-transform 600ms 600ms;
    transition: bottom 600ms, -webkit-transform 600ms 600ms;
    transition: bottom 600ms, transform 600ms 600ms;
    transition: bottom 600ms, transform 600ms 600ms, -webkit-transform 600ms 600ms;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .text,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu label .text {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .open_offcanvas_menu {
    display: none;
  }
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .toggle_offcanvas_menu .close_offcanvas_menu {
    display: block;
  }
  .offcanvas_menu {
    background-color: #ffffff;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: start;
            justify-content: flex-start;
    min-height: 100%;
    overflow: hidden;
    padding: 1.5em 0;
    position: absolute;
    -webkit-transition: opacity 480ms, visibility 480ms, width 1200ms ease, -webkit-transform 480ms;
    transition: opacity 480ms, visibility 480ms, width 1200ms ease, -webkit-transform 480ms;
    transition: opacity 480ms, transform 480ms, visibility 480ms, width 1200ms ease;
    transition: opacity 480ms, transform 480ms, visibility 480ms, width 1200ms ease, -webkit-transform 480ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 4.0625rem;
    z-index: 5000;
    right: 0;
    -webkit-transform-origin: right;
            transform-origin: right;
  }
  .offcanvas_menu .offcanvas_menu_content {
    margin-left: 31.25rem;
    opacity: 0;
    -webkit-transition: margin 1800ms ease, opacity 1800ms ease;
    transition: margin 1800ms ease, opacity 1800ms ease;
    width: 70vw;
  }
  .offcanvas_menu .offcanvas_menu_content > ul {
    width: 70vw;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li {
    padding: 0 1.5em;
    position: relative;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    letter-spacing: calc((1.5rem + 1.625
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1.5rem;
    color: #111111;
    display: block;
    font-weight: 400;
    line-height: 3.9375rem;
    padding-top: 1.125rem;
    text-decoration: none;
    text-transform: uppercase;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:hover {
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul > li > a:active {
    color: #eb6c5f;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > a {
    color: #eb6c5f;
  }
  .offcanvas_menu .offcanvas_menu_content > ul li.expanded > ul {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul {
    display: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul:target {
    display: block;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul li {
    display: inline-block;
    padding: 0.375em 1.5em 0.375em 0;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a {
    border-right: 0.25rem solid #eb6c5f;
    color: #111111;
    display: inline;
    padding-right: 1.5em;
    text-decoration: none;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a.active {
    color: #eb6c5f;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul a:hover {
    text-decoration: underline;
  }
  .offcanvas_menu .offcanvas_menu_content > ul ul ul {
    border-bottom: 0.25rem solid #eaeaea;
    border-top: 0.25rem solid #eaeaea;
  }
  .onlinetools_popup_overview .entry {
    margin: 0 0 0.75em;
  }
  .onlinetools_popup_overview .description {
    display: block;
    margin-bottom: 0.75em;
  }
  .onlinetools_popup_overview .touch_link {
    background: #f7f7f7;
    border-left: 0.25rem solid #eaeaea;
    margin-bottom: 0.75em;
    padding: 0.75em 1.5em;
    -webkit-transition: border 600ms ease;
    transition: border 600ms ease;
  }
  .onlinetools_popup_overview .touch_link .title {
    color: #111111;
    margin: 0 0 0.375em;
    padding: 0;
    text-decoration: none;
  }
  .onlinetools_popup_overview .touch_link:hover,
  .onlinetools_popup_overview .touch_link:focus {
    border-left: 0.25rem solid #eaeaea;
    -webkit-transition: border 600ms ease;
    transition: border 600ms ease;
  }
  .onlinetools_popup_overview .touch_link:hover .title,
  .onlinetools_popup_overview .touch_link:focus .title {
    color: #eb6c5f;
  }
  .popup_overlay {
    -webkit-backdrop-filter: blur(0.125rem);
    background: rgba(255, 255, 255, 0.5);
    bottom: 0;
    left: 0;
    opacity: 0;
    padding: 1% 0;
    position: fixed;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms, visibility 600ms;
    transition: opacity 600ms, visibility 600ms;
    visibility: hidden;
    z-index: -1;
  }
  .popup_overlay.show {
    opacity: 1;
    visibility: visible;
    z-index: 9000;
  }
  .popup_header {
    padding: 0 0 0 1.5em;
  }
  .popup_close {
    -webkit-box-align: center;
            align-items: center;
    background: #eb6c5f;
    border-radius: 0 0 0 0;
    display: -webkit-box;
    display: flex;
    float: right;
    -webkit-box-pack: center;
            justify-content: center;
    margin-right: 0.0625rem;
    padding: 0.42857143em;
    text-align: center;
    height: 2.75rem;
    width: 2.75rem;
  }
  .popup_close .icon {
    fill: #ffffff;
    height: 1.5rem;
    width: 1.5rem;
  }
  .popup_content {
    height: 100%;
    margin: 0 auto;
    max-width: 100rem;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
    -webkit-transition: opacity 600ms, visibility 600ms, -webkit-transform 600ms;
    transition: opacity 600ms, visibility 600ms, -webkit-transform 600ms;
    transition: opacity 600ms, transform 600ms, visibility 600ms;
    transition: opacity 600ms, transform 600ms, visibility 600ms, -webkit-transform 600ms;
    visibility: hidden;
    z-index: 9500;
  }
  .popup_content.show {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
    visibility: visible;
  }
  .popup_content .content {
    background: #ffffff;
    bottom: 0;
    box-shadow: 0 0 1.5625rem rgba(0, 0, 0, 0.2);
    left: 0;
    position: absolute;
    right: 0;
    top: 2.75rem;
  }
  .popup_iframe {
    background: #ffffff;
    border: 0;
    height: 100%;
    opacity: 0;
    position: relative;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    width: 100%;
    z-index: 1;
  }
  .popup_iframe.show {
    opacity: 1;
  }
  .open_street_map .map {
    height: 21.875rem;
    background-color: #f7f7f7;
    cursor: move;
    position: relative;
    width: 100%;
  }
  .open_street_map .map_overlay {
    -webkit-box-align: center;
            align-items: center;
    background-color: rgba(247, 247, 247, 0.85);
    bottom: 0;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
            justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-transition: opacity 600ms;
    transition: opacity 600ms;
    z-index: 1;
  }
  .open_street_map .map_overlay.show {
    opacity: 1;
    -webkit-transition-delay: 0;
            transition-delay: 0;
  }
  .open_street_map .map_overlay.show p {
    font-size: 120%;
    margin: 1.5em 3em;
    text-align: center;
  }
  .open_street_map .required_description {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .form_disclaimer {
    margin: 1.5em 0 0 0;
  }
  .open_street_map .plan_route {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5em 0;
    position: relative;
  }
  .open_street_map .plan_route .saddr {
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
  }
  .open_street_map .plan_route .select_wrapper {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-flex: 1;
            flex-grow: 1;
    flex-shrink: 1;
    margin-top: 0.75em;
  }
  .open_street_map .plan_route .form_disclaimer {
    margin-top: 1.5em;
  }
  .open_street_map .ol-viewport {
    touch-action: pan-y !important;
  }
  .open_street_map .ol-overlaycontainer-stopevent button {
    -webkit-box-align: center;
            align-items: center;
    background: #eb6c5f;
    border: 0.25rem solid #eb6c5f;
    border-radius: 0;
    color: #ffffff;
    display: -webkit-inline-box;
    display: inline-flex;
    font-weight: 700;
    height: 2.75rem;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    padding: 0;
    text-decoration: none;
    -webkit-transition: background 600ms, border 600ms, color 600ms;
    transition: background 600ms, border 600ms, color 600ms;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    width: 2.75rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:focus {
    background: #f1968c;
    border: 0.25rem solid #f1968c;
    box-shadow: 0 0 0 0.75rem rgba(235, 108, 95, 0.2);
    color: #ffffff;
    outline: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent button:hover {
    background: #f1968c;
    border: 0.25rem solid #f1968c;
    color: #111111;
  }
  .open_street_map .ol-overlaycontainer-stopevent button.disabled {
    cursor: default;
    opacity: 0.6;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out,
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoomslider {
    position: absolute;
    right: 0.3125rem;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-in {
    line-height: 1;
    top: 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-zoom-out {
    line-height: 1;
    top: 3.375rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-rotate-reset {
    display: none;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution {
    -webkit-box-align: center;
            align-items: center;
    bottom: 0;
    cursor: default;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: end;
            justify-content: flex-end;
    right: 0;
    text-align: right;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    z-index: 99;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul {
    -webkit-box-align: center;
            align-items: center;
    background: rgba(255, 255, 255, 0.85);
    color: black;
    display: -webkit-box;
    display: flex;
    height: 3.25rem;
    -webkit-box-pack: end;
            justify-content: flex-end;
    list-style: none !important;
    margin: 0;
    padding: 0;
    text-shadow: 0 0 0.125rem rgba(0, 0, 0, 0.15);
    -webkit-transform-origin: center right;
            transform-origin: center right;
    -webkit-transition: opacity 600ms, -webkit-transform 600ms;
    transition: opacity 600ms, -webkit-transform 600ms;
    transition: transform 600ms, opacity 600ms;
    transition: transform 600ms, opacity 600ms, -webkit-transform 600ms;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li {
    -webkit-box-align: center;
            align-items: center;
    background-image: none !important;
    display: -webkit-box;
    display: flex;
    font-size: 85%;
    margin: 0 0.75em;
    padding: 0;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution ul li a {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-inline-box;
    display: inline-flex;
    margin: 0 0.3125rem;
  }
  .open_street_map .ol-overlaycontainer-stopevent .ol-attribution button {
    display: none;
  }
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-in,
  .open_street_map.disable_ui .ol-overlaycontainer-stopevent .ol-zoom-out {
    display: none;
  }
  .open_street_map .form_disclaimer {
    background: #f7f7f7;
    border-radius: 0;
    color: #111111;
    margin-bottom: 0.75em;
    padding: 1em 1.5em;
  }
  .opening_times .title {
    padding-top: 0.75em;
  }
  .opening_times .entries {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
  }
  .opening_times .entry {
    margin-right: 2.25em;
  }
  .preloader {
    display: block;
    position: relative;
  }
  .preloader .icon_spin {
    -webkit-animation: preloader_rotate 1800ms linear infinite;
            animation: preloader_rotate 1800ms linear infinite;
    display: block;
    fill: #eaeaea;
    height: 3rem;
    left: 50%;
    margin: -1.5rem 0 0 -1.5rem;
    opacity: 1;
    position: absolute;
    top: 50%;
    -webkit-transition: 600ms;
    transition: 600ms;
    visibility: visible;
    width: 3rem;
  }
  .preloader .icon_spin.hide {
    opacity: 0;
    visibility: hidden;
  }
  .no-cssanimations .icon_spin {
    display: none;
  }
  .highlight_keyword {
    background: #eb6c5f;
    color: #ffffff;
    outline: 0.125rem solid #eb6c5f;
  }
  .steuernews_video_overview {
    margin-bottom: 1.5em;
  }
  .steuernews_video_overview .entry {
    display: inline-block;
  }
  .steuernews_video_overview .image {
    margin-bottom: 0.75em;
    position: relative;
  }
  .steuernews_video_overview .video_play {
    position: relative;
  }
  .steuernews_video_overview .video_play .icon {
    fill: #eb6c5f;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_preview {
    margin-bottom: 0.75em;
  }
  .steuernews_preview .entry {
    background: #f7f7f7;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 1.5em;
  }
  .steuernews_preview .entry:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: -webkit-transform 600ms ease;
    transition: -webkit-transform 600ms ease;
    transition: transform 600ms ease;
    transition: transform 600ms ease, -webkit-transform 600ms ease;
  }
  .steuernews_preview .image {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    overflow: hidden;
  }
  .steuernews_preview .image img {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 600ms ease;
    transition: -webkit-transform 600ms ease;
    transition: transform 600ms ease;
    transition: transform 600ms ease, -webkit-transform 600ms ease;
  }
  .steuernews_preview .video_play {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    -webkit-box-pack: center;
            justify-content: center;
    margin: 0;
    overflow: hidden;
  }
  .steuernews_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
    padding: 0.5em 0.75em;
  }
  .steuernews_preview .title {
    text-decoration: none;
    word-wrap: break-word;
  }
  .news_image {
    margin-bottom: 1.5em;
  }
  .steuernews_issues ul {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding-left: 0;
  }
  .steuernews_issues ul li {
    padding-left: 0;
  }
  .steuernews_issues a {
    background: #f7f7f7;
    border-radius: 0;
    display: inline-block;
    margin: 0 0.75em 0.75em 0;
    padding: 0 1.5em;
    white-space: nowrap;
    line-height: 2.75rem;
  }
  .steuernews_sidebar {
    margin: 1.5em 0 0.75em;
  }
  .steuernews_current_issue {
    margin-bottom: 0.75em;
  }
  .steuernews_current_issue ul {
    list-style: none;
    margin: 0 0 0.75em;
    padding-left: 1.125em;
  }
  .steuernews_current_issue ul > li {
    margin: 0.375em 0 0.375em 0.375em;
    padding-left: 0.375em;
    position: relative;
  }
  .steuernews_current_issue ul > li::after {
    background: #eb6c5f;
    bottom: 0.0625rem;
    content: "";
    left: -1.125em;
    position: absolute;
    top: 0.0625rem;
    width: 0.25rem;
    z-index: 1;
  }
  .team_overview .entry {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
            flex-direction: column-reverse;
    margin-bottom: 0.75em;
  }
  .team_overview .title {
    margin: 0;
    padding: 0;
  }
  .team_overview .photo {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0 1.5em 0.75em 0;
    max-width: 12.5rem;
  }
  .team_overview .photo img {
    display: block;
  }
  .team_overview .info {
    -webkit-box-flex: 1;
            flex: 1 1 auto;
    margin-bottom: 0.75em;
  }
  .team_entry .photo {
    margin-bottom: 0.75em;
    max-width: 8.75rem;
  }
  .team_entry .photo img {
    display: block;
  }
  .team_entry .info {
    margin-bottom: 0.75em;
  }
  .teaser_overview {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
  }
  .teaser_overview .teaser {
    -webkit-box-align: center;
            align-items: center;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    -webkit-box-pack: start;
            justify-content: flex-start;
    padding: 1.125rem;
    text-decoration: none;
  }
  .teaser_overview .teaser .text {
    letter-spacing: calc((1rem + 0.5
                                 * (100vw - 20rem)
                                 / (73.75))
                                 / 1000 * 0);
    font-size: 1rem;
    color: #ffffff;
    line-height: 1.35;
    padding-top: 1.125rem;
    text-align: center;
    text-decoration: none;
    width: 100%;
  }
  .teaser_overview .teaser.scheme_red {
    background: #eb6c5f;
  }
  .teaser_overview .teaser.scheme_green {
    background: #0da193;
  }
  .teaser_overview .teaser.scheme_orange {
    background: #f0893e;
  }
  .teaser_overview .teaser.scheme_violet {
    background: #bb5191;
  }
  .teaser_overview .teaser .icon svg {
    fill: #ffffff;
    height: 6.25rem;
    width: 6.25rem;
  }
  .teaser_overview .teaser:hover {
    -webkit-transition: background 300ms ease;
    transition: background 300ms ease;
  }
  .teaser_overview .teaser:hover.scheme_red {
    background: #fd4634;
  }
  .teaser_overview .teaser:hover.scheme_green {
    background: #009586;
  }
  .teaser_overview .teaser:hover.scheme_orange {
    background: #ff7816;
  }
  .teaser_overview .teaser:hover.scheme_violet {
    background: #c72c89;
  }
  .tooltip_wrapper {
    display: block;
    position: relative;
  }
  [role="tooltip"] {
    background: #f7f7f7;
    border: 0.25rem solid #eaeaea;
    border-radius: 0;
    bottom: 100%;
    color: #111111;
    font-size: 85%;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.75em;
    position: absolute;
    right: 0;
    text-align: center;
    -webkit-transform: translateY(-0.3125rem);
            transform: translateY(-0.3125rem);
    z-index: 50;
  }
  [role="tooltip"]:after {
    border: 0.625rem solid transparent;
    border-top-color: #f7f7f7;
    bottom: -1.25rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.625rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"]:before {
    border: 0.6875rem solid transparent;
    border-top-color: #eaeaea;
    bottom: -1.375rem;
    content: "";
    display: block;
    height: 0;
    left: 50%;
    margin-left: -0.6875rem;
    position: absolute;
    width: 0;
  }
  [role="tooltip"][aria-hidden="true"] {
    display: none;
  }
  .video_overview .entry,
  .video_infolists_overview .entry,
  .video_infolist_overview .entry,
  .video_tutorial_overview .entry {
    background: #f7f7f7;
    display: inline-block;
    -webkit-box-flex: 1;
            flex-grow: 1;
    margin-bottom: 1.5em;
  }
  .video_overview .entry:hover .icon,
  .video_infolists_overview .entry:hover .icon,
  .video_infolist_overview .entry:hover .icon,
  .video_tutorial_overview .entry:hover .icon {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: -webkit-transform 600ms ease;
    transition: -webkit-transform 600ms ease;
    transition: transform 600ms ease;
    transition: transform 600ms ease, -webkit-transform 600ms ease;
  }
  .video_overview .image,
  .video_infolists_overview .image,
  .video_infolist_overview .image,
  .video_tutorial_overview .image,
  .video_overview figure,
  .video_infolists_overview figure,
  .video_infolist_overview figure,
  .video_tutorial_overview figure {
    margin: 0;
  }
  .video_overview .image img,
  .video_infolists_overview .image img,
  .video_infolist_overview .image img,
  .video_tutorial_overview .image img,
  .video_overview figure img,
  .video_infolists_overview figure img,
  .video_infolist_overview figure img,
  .video_tutorial_overview figure img {
    width: 100%;
  }
  .video_overview figcaption,
  .video_infolists_overview figcaption,
  .video_infolist_overview figcaption,
  .video_tutorial_overview figcaption {
    font-size: 100%;
    padding: 0.125rem;
  }
  .video_overview .video_play,
  .video_infolists_overview .video_play,
  .video_infolist_overview .video_play,
  .video_tutorial_overview .video_play {
    position: relative;
  }
  .video_overview .video_play .icon,
  .video_infolists_overview .video_play .icon,
  .video_infolist_overview .video_play .icon,
  .video_tutorial_overview .video_play .icon {
    fill: #eb6c5f;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 600ms ease;
    transition: -webkit-transform 600ms ease;
    transition: transform 600ms ease;
    transition: transform 600ms ease, -webkit-transform 600ms ease;
  }
  .video_wrapper {
    height: 0;
    margin-bottom: 1.5em;
    padding-top: 56.25%;
    position: relative;
  }
  .video_wrapper video,
  .video_wrapper iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
  .video_wrapper video::cue {
    background: #000000;
    color: #ffffff;
  }
  .video_preview {
    margin-bottom: 0.75em;
  }
  .video_preview .entry {
    display: -webkit-box;
    display: flex;
    margin-bottom: 1.5em;
  }
  .video_preview .entry:hover img {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    -webkit-transition: -webkit-transform 600ms ease;
    transition: -webkit-transform 600ms ease;
    transition: transform 600ms ease;
    transition: transform 600ms ease, -webkit-transform 600ms ease;
  }
  .video_preview figure {
    -webkit-box-flex: 0;
            flex: 0 0 auto;
    margin: 0;
    -webkit-box-ordinal-group: 2;
            order: 1;
    padding-right: 1.5em;
  }
  .video_preview figure img {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transition: -webkit-transform 600ms ease;
    transition: -webkit-transform 600ms ease;
    transition: transform 600ms ease;
    transition: transform 600ms ease, -webkit-transform 600ms ease;
  }
  .video_preview .teaser_text {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .video_preview .video_play {
    overflow: hidden;
    position: relative;
  }
  .video_preview .video_play .icon {
    fill: #eb6c5f;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
  .steuernews_preview .video_play {
    position: relative;
  }
  .steuernews_preview .video_play .icon {
    fill: #eb6c5f;
    height: 2.75rem;
    left: 0;
    margin: -1.375rem 0 0 0;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
  }
}
@media screen and (min-width: 61.875rem) {
  .author_hor {
    position: fixed;
  }
}
@media screen and (min-width: 20rem) {
  .button > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .button:active > span,
  .button.active > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .button:active > span,
  .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 61.875rem) {
  .chart_organigram {
    display: block;
    margin-bottom: 0.75em;
  }
}
@media screen and (min-width: 61.875rem) {
  .edit_in_cms {
    bottom: 1.25rem;
    display: inline-block;
    background: #eb6c5f;
    border-radius: 0;
    color: #ffffff;
    padding: 0.75em 1.5em;
    position: fixed;
    right: 1.25rem;
    text-decoration: none;
    -webkit-transition: background 600ms;
    transition: background 600ms;
    z-index: 9999;
  }
  .edit_in_cms:hover {
    background: #111111;
    color: #ffffff;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_red .button > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_red .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_red .button:active > span,
  .scheme_red .button.active > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_red .button:active > span,
  .scheme_red .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_violet .button > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_violet .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_violet .button:active > span,
  .scheme_violet .button.active > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_violet .button:active > span,
  .scheme_violet .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_orange .button > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_orange .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_orange .button:active > span,
  .scheme_orange .button.active > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_orange .button:active > span,
  .scheme_orange .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_green .button > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_green .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_green .button:active > span,
  .scheme_green .button.active > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_green .button:active > span,
  .scheme_green .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_blue .button > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_blue .button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .scheme_blue .button:active > span,
  .scheme_blue .button.active > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .scheme_blue .button:active > span,
  .scheme_blue .button.active > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 75rem) {
  .company_logo {
    margin-left: auto;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo .company_logo_name {
    font-size: calc(2.0625rem + 1.1875
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo .company_logo_name {
    font-size: 3.25rem;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo .company_logo_name {
    line-height: 4rem;
  }
}
@media screen and (min-width: 20rem) {
  .company_logo .company_logo_addition {
    font-size: calc(0.75rem + 0.25
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo .company_logo_addition {
    font-size: 1rem;
  }
}
@media screen and (min-width: 35rem) {
  .company_logo .company_logo_addition {
    line-height: 1.5625rem;
    margin-right: 0;
    width: 26.875rem;
  }
}
@media screen and (min-width: 75rem) {
  .company_logo .company_logo_addition {
    width: 23.75rem;
  }
}
@media screen and (min-width: 93.75rem) {
  .company_logo .company_logo_addition {
    width: 30rem;
  }
}
@media screen and (min-width: 20rem) {
  .cookie_banner .close > span {
    min-height: calc(2.25rem + 0
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .cookie_banner .close > span {
    min-height: 2.25rem;
  }
}
@media screen and (min-width: 75rem) {
  footer {
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 100;
    margin: 0;
  }
}
@media screen and (min-width: 75rem) {
  footer .address {
    background: #f7f7f7;
    padding: 0.3125rem 0.625rem;
    margin-bottom: 0.3125rem;
  }
  footer .address p {
    margin: 0;
  }
}
@media screen and (min-width: 75rem) {
  .related_links ul li {
    background: #f7f7f7;
    padding: 0.3125rem 0.625rem;
  }
}
@media screen and (min-width: 75rem) {
  .startpage .related_links li {
    background: white;
    padding: 0;
  }
}
@media screen and (min-width: 75rem) {
  .startpage footer .address {
    background: white;
    padding: 0;
  }
}
@media screen and (min-width: 20rem) {
  .file {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    line-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    line-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_name {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_name {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .file .file_button > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file .file_button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"] {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"] {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .file [type="file"]:active ~ .file_button > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 35rem) {
  .formular .button_wrapper {
    text-align: right;
  }
}
@media screen and (min-width: 20rem) {
  button {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  button {
    height: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  button {
    width: auto;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_entry {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_entry {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .travel_mode_radio > span {
    min-height: calc(2.25rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .travel_mode_radio > span {
    min-height: 2.875rem;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .step:before {
    font-size: inherit;
  }
}
@media screen and (min-width: 35rem) {
  .google_maps .instructions,
  .google_maps .distance {
    font-size: inherit;
  }
}
@media screen and (min-width: 20rem) {
  .google_maps .infowindow {
    max-width: calc(13.75rem + 8.75
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .google_maps .infowindow {
    max-width: 22.5rem;
  }
}
@media screen and (min-width: 20rem) {
  .container {
    padding: 0 calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75));
  }
  @supports (padding-left: env(safe-area-inset-left)) {
    html.mobile .container {
      padding-left: max(calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75)), env(safe-area-inset-left));
      padding-right: max(calc(0.9375rem + 2.1875
                                         * (100vw - 20rem)
                                         / (73.75)), env(safe-area-inset-right));
    }
  }
}
@media screen and (min-width: 93.75rem) {
  .container {
    padding: 0 3.125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row {
    margin: 0 calc((-0.9375rem / 2) - (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row {
    margin: 0 -1.5625rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin {
    margin: 0 calc((-0.9375rem / 4) - (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin {
    margin: 0 -0.78125rem;
  }
}
@media screen and (min-width: 20rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 calc((0.9375rem / 4) + (2.1875 / 4)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .row.half_column_margin > [class*="span"] {
    padding: 0 0.78125rem;
  }
}
@media screen and (min-width: 30rem) {
  .row.around_small {
    justify-content: space-around;
  }
  .row.between_small {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_small {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_small {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_small {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_small {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_small {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_small {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_small {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 35rem) {
  .row.around_medium {
    justify-content: space-around;
  }
  .row.between_medium {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_medium {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_medium {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_medium {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_medium {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_medium {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_medium {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_medium {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 47.5rem) {
  .row.around_large {
    justify-content: space-around;
  }
  .row.between_large {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_large {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_large {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_large {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_large {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_large {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_large {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_large {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 61.875rem) {
  .row.around_xlarge {
    justify-content: space-around;
  }
  .row.between_xlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 75rem) {
  .row.around_xxlarge {
    justify-content: space-around;
  }
  .row.between_xxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 93.75rem) {
  .row.around_xxxlarge {
    justify-content: space-around;
  }
  .row.between_xxxlarge {
    -webkit-box-pack: justify;
            justify-content: space-between;
  }
  .row.start_xxxlarge {
    -webkit-box-pack: start;
            justify-content: flex-start;
  }
  .row.center_xxxlarge {
    -webkit-box-pack: center;
            justify-content: center;
  }
  .row.end_xxxlarge {
    -webkit-box-pack: end;
            justify-content: flex-end;
  }
  .row.top_xxxlarge {
    -webkit-box-align: start;
            align-items: flex-start;
  }
  .row.middle_xxxlarge {
    -webkit-box-align: center;
            align-items: center;
  }
  .row.bottom_xxxlarge {
    -webkit-box-align: end;
            align-items: flex-end;
  }
  .row.baseline_xxxlarge {
    -webkit-box-align: baseline;
            align-items: baseline;
  }
}
@media screen and (min-width: 20rem) {
  [class*="span"] {
    padding: 0 calc((0.9375rem / 2) + (2.1875 / 2)
                                         * (100vw - 20rem)
                                         / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  [class*="span"] {
    padding: 0 1.5625rem;
  }
}
@media screen and (min-width: 30rem) {
  .span1_small {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_small {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_small {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_small {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_small {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_small {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_small {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_small {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_small {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_small {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_small {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_small {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_small {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_small {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_small {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_small {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_small {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_small {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_small {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_small {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_small {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_small {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_small {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_small {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 35rem) {
  .span1_medium {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_medium {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_medium {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_medium {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_medium {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_medium {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_medium {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_medium {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_medium {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_medium {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_medium {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_medium {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_medium {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_medium {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_medium {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_medium {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_medium {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_medium {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_medium {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_medium {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_medium {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_medium {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_medium {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_medium {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 47.5rem) {
  .span1_large {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_large {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_large {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_large {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_large {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_large {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_large {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_large {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_large {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_large {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_large {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_large {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_large {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_large {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_large {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_large {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_large {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_large {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_large {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_large {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_large {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_large {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_large {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_large {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 61.875rem) {
  .span1_xlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .span1_xxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 93.75rem) {
  .span1_xxxlarge {
    flex-basis: 8.33333333%;
    max-width: 8.33333333%;
  }
  .order1_xxxlarge {
    -webkit-box-ordinal-group: 2;
            order: 1;
  }
  .span2_xxxlarge {
    flex-basis: 16.66666667%;
    max-width: 16.66666667%;
  }
  .order2_xxxlarge {
    -webkit-box-ordinal-group: 3;
            order: 2;
  }
  .span3_xxxlarge {
    flex-basis: 25%;
    max-width: 25%;
  }
  .order3_xxxlarge {
    -webkit-box-ordinal-group: 4;
            order: 3;
  }
  .span4_xxxlarge {
    flex-basis: 33.33333333%;
    max-width: 33.33333333%;
  }
  .order4_xxxlarge {
    -webkit-box-ordinal-group: 5;
            order: 4;
  }
  .span5_xxxlarge {
    flex-basis: 41.66666667%;
    max-width: 41.66666667%;
  }
  .order5_xxxlarge {
    -webkit-box-ordinal-group: 6;
            order: 5;
  }
  .span6_xxxlarge {
    flex-basis: 50%;
    max-width: 50%;
  }
  .order6_xxxlarge {
    -webkit-box-ordinal-group: 7;
            order: 6;
  }
  .span7_xxxlarge {
    flex-basis: 58.33333333%;
    max-width: 58.33333333%;
  }
  .order7_xxxlarge {
    -webkit-box-ordinal-group: 8;
            order: 7;
  }
  .span8_xxxlarge {
    flex-basis: 66.66666667%;
    max-width: 66.66666667%;
  }
  .order8_xxxlarge {
    -webkit-box-ordinal-group: 9;
            order: 8;
  }
  .span9_xxxlarge {
    flex-basis: 75%;
    max-width: 75%;
  }
  .order9_xxxlarge {
    -webkit-box-ordinal-group: 10;
            order: 9;
  }
  .span10_xxxlarge {
    flex-basis: 83.33333333%;
    max-width: 83.33333333%;
  }
  .order10_xxxlarge {
    -webkit-box-ordinal-group: 11;
            order: 10;
  }
  .span11_xxxlarge {
    flex-basis: 91.66666667%;
    max-width: 91.66666667%;
  }
  .order11_xxxlarge {
    -webkit-box-ordinal-group: 12;
            order: 11;
  }
  .span12_xxxlarge {
    flex-basis: 100%;
    max-width: 100%;
  }
  .order12_xxxlarge {
    -webkit-box-ordinal-group: 13;
            order: 12;
  }
}
@media screen and (min-width: 75rem) {
  .header_background {
    padding-bottom: 100vh;
  }
}
@media screen and (min-width: 47.5rem) {
  .header_content {
    padding-bottom: 10vh;
  }
}
@media screen and (min-width: 75rem) {
  .header_wrapper {
    background: #eb6c5f;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    left: 0;
    padding: 1.125rem 0 1.375rem;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 99;
  }
}
@media screen and (min-width: 47.5rem) {
  .header_wrapper .call {
    display: none;
  }
}
@media screen and screen and (min-height: 50px) {
  .logo_sportverein {
    display: none!important;
  }
}
@media screen and (min-width: 75rem) {
  .sportverein_wrapper .entry .logo_sportverein {
    margin: 0 0 1.5em 0;
  }
}
@media screen and (min-width: 75rem) {
  .sportverein_wrapper .entry {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-top: 0;
    -webkit-box-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
            align-items: flex-start;
  }
}
@media screen and (min-width: 47.5rem) {
  .hideme_mobile {
    display: block;
  }
}
@media screen and (min-width: 30rem) {
  .vpi_diagram_grid_wrapper .vpi_diagram_label_legend_wrapper {
    padding-left: 3.75rem;
  }
}
@media screen and (min-width: 30rem) {
  .vpi_diagram_grid_wrapper .vpi_diagram text {
    font-size: 100%;
  }
}
@media screen and (min-width: 20rem) {
  .map_chart {
    height: calc(16.25rem + 12.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .map_chart {
    height: 28.75rem;
  }
}
@media screen and (min-width: 20rem) {
  .map_chart .map_chart_wrapper {
    height: calc(16.25rem + 12.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .map_chart .map_chart_wrapper {
    height: 28.75rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .multi_columns {
    -webkit-column-count: 2;
            column-count: 2;
    -webkit-column-gap: 3em;
            column-gap: 3em;
  }
}
@media screen and (min-width: 75rem) {
  .left_column {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: fixed;
    top: 0;
    width: 30vw;
    right: auto;
  }
}
@media screen and (min-width: 75rem) {
  .main_wrapper {
    margin-left: 30vw;
    min-height: 100vh;
    width: 50vw;
  }
}
@media screen and (min-width: 61.875rem) {
  .main_offset {
    padding: 3em 0 3em 2.25em;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_offcanvas_menu {
    margin: 0 1.25rem;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_offcanvas_menu label .icon {
    height: 3rem;
    width: 3.4375rem;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_offcanvas_menu label .line {
    height: 0.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_offcanvas_menu label .line_1 {
    top: 0.1875rem;
  }
}
@media screen and (min-width: 75rem) {
  .toggle_offcanvas_menu label .line_3 {
    bottom: 0.1875rem;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu_state:checked ~ .offcanvas_menu_wrapper .offcanvas_menu,
  .offcanvas_menu_state:target ~ .offcanvas_menu_wrapper .offcanvas_menu {
    width: 70vw;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu {
    -webkit-box-align: center;
            align-items: center;
    position: fixed;
    width: 7.5rem;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu .offcanvas_menu_content {
    width: 60vw;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu .offcanvas_menu_content {
    width: 40vw;
  }
}
@media screen and (min-width: 30rem) {
  .offcanvas_menu .offcanvas_menu_content > ul {
    width: 60vw;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul {
    width: 40vw;
  }
}
@media screen and (min-width: 20rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: calc(1.5rem + 1.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    font-size: 3.125rem;
  }
}
@media screen and (min-width: 75rem) {
  .offcanvas_menu .offcanvas_menu_content > ul > li > a {
    padding-top: 2.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    height: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_close {
    width: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_close {
    width: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .popup_content .content {
    top: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .popup_content .content {
    top: 3.375rem;
  }
}
@media screen and (min-width: 20rem) {
  .open_street_map .map {
    height: calc(21.875rem + 12.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .open_street_map .map {
    height: 34.375rem;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route {
    -webkit-box-align: end;
            align-items: flex-end;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .saddr {
    margin: 0 1.5em 0 0;
    max-width: 40.625rem;
  }
  .open_street_map .plan_route .saddr input {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper {
    margin: 0 1.5em 0 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .select_wrapper .select {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .open_street_map .plan_route .button_wrapper button {
    margin-bottom: 0;
  }
}
@media screen and (min-width: 47.5rem) {
  .news_image {
    float: right;
    margin-left: 1.5em;
    margin-bottom: 0;
  }
  .news_image + * {
    padding-top: 0;
  }
}
@media screen and (min-width: 20rem) {
  .steuernews_issues a {
    line-height: calc(2.75rem + 0.625
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .steuernews_issues a {
    line-height: 3.375rem;
  }
}
@media screen and (min-width: 35rem) {
  .team_overview .entry {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
            flex-direction: row-reverse;
  }
}
@media screen and (min-width: 75rem) {
  .teaser_overview .teaser {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row;
    height: 25vh;
    padding: 3rem;
  }
}
@media screen and (min-width: 20rem) {
  .teaser_overview .teaser .text {
    font-size: calc(1rem + 0.5
                            * (100vw - 20rem)
                            / (73.75));
  }
}
@media screen and (min-width: 93.75rem) {
  .teaser_overview .teaser .text {
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 75rem) {
  .teaser_overview .teaser .text {
    padding-left: 3rem;
    padding-top: 0;
    text-align: left;
  }
}
@media print {
  .company_logo {
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
            flex-direction: column;
    margin-bottom: 0.75em;
    text-decoration: none;
  }
  .company_logo .company_logo_name {
    font-size: 2.875rem;
    line-height: 2.875rem;
  }
  .company_logo .company_logo_addition {
    font-size: 1rem;
  }
  .company_logo img {
    display: block;
  }
  .hideme_print,
  .cms_live_management {
    display: none !important;
  }
  .showme_print {
    display: inherit !important;
    height: auto !important;
    max-height: none !important;
  }
  * {
    background: transparent !important;
    border-color: black !important;
    color: black !important;
    text-shadow: none !important;
  }
  *:before {
    color: black !important;
  }
  @page {
    margin: 2cm;
  }
  .contact_wrapper .postal_address > span {
    display: block;
  }
  .contact {
    display: -webkit-box;
    display: flex;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    page-break-after: avoid;
  }
  .google_maps .distance::before {
    content: " - ";
    display: inline;
  }
  main a {
    text-decoration: underline;
  }
  main a.touch_link {
    margin-bottom: 1.5em;
    page-break-inside: avoid;
  }
  .table {
    overflow-y: inherit;
  }
  tr {
    page-break-inside: avoid;
  }
  dl {
    margin: 0;
  }
  dt {
    font-weight: 700;
    margin-bottom: 0.75em;
  }
  dd {
    margin: 0;
  }
  [role="tooltip"] {
    display: none;
  }
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
  .search dl br,
  .search dl em,
  .search dl img {
    display: none;
  }
  body {
    display: block;
    font-size: 85%;
    overflow-x: visible;
  }
  .page {
    display: block;
  }
  main {
    padding-top: 1.5em;
  }
  .qr_code_print {
    border-top: 0.25rem solid;
    margin: 1.5em 0 0;
    padding: 0.75em 0 0;
    page-break-inside: avoid;
  }
  .qr_code_print:after {
    clear: both;
  }
  .qr_code_print:after,
  .qr_code_print:before {
    content: "";
    display: table;
  }
  .qr_code_print img {
    border: 0.0625rem solid black;
    float: left;
  }
  .qr_code_print p {
    margin-left: 10rem;
  }
}
